import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../Contexts/AppContext";
import SelectTimeframes from "./Coins/SelectTimeframes";
import { default as CPVote } from "./Coins/Vote";
import { Title } from "../Pages/SingleCoin";
import { Link, useParams } from "react-router-dom";
import UserContext from "../Contexts/User";
import RangeSilder from "./Users/RangeSilder";
import Countdown from "react-countdown";
import { VoteResultProps } from "../common/models/Vote";
import moment from "moment";
import { getGMTTimestampWithValue, getGoldenReward, getNewGoldVtoe } from "common/utils/helper";
import styled from "styled-components";

export const colors = ["#6352e8", "white"];
const I = styled.i`
  border-radius: 50%;
  position: absolute;
  font-size: 11px;
  top: -18px;  
  font-weight:300;
  color: #6352E8; 
  width: 16px;
  height: 16px;
  text-align: center;  
`;

type VoteFormProps<T> = {
  option1: T;
  option2: T;
  id: string;
  texts: {
    yourVote: string;
    selectTimeFrame: string;
    tooltip: string;
  };
  canVote: boolean;
  selectedTimeFrameArray?: any;
  selectedTimeFrame?: number;
  setSelectedTimeFrame: (n: number) => void;
  selectedOption?: number;
  setSelectedOption: (n: number) => void;
  submit: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
  width?: number | string;
  cssDegree?: any;
  votePrice?: any;
  votedDetails?: any;
  hideButton?: any;
  setHideButton?: React.Dispatch<React.SetStateAction<number[]>>;
  setpopUpOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  vote: VoteResultProps,
  disableVoteButton?: boolean
};
const VoteForm = function <
  T extends {
    image: string | React.ReactNode;
    alt: string;
    title?: JSX.Element;
  }
>({
  option1,
  option2,
  id,
  texts,
  canVote,
  selectedTimeFrameArray,
  selectedTimeFrame,
  setSelectedTimeFrame,
  selectedOption,
  setSelectedOption,
  children,
  disabled,
  width,
  submit,
  cssDegree,
  votePrice,
  votedDetails,
  hideButton,
  setHideButton,
  setpopUpOpen,
  vote,
  disableVoteButton
}: VoteFormProps<T>) {
  const { timeframes, login, remainingTimer, voteRules, afterVotePopup, oneVoteOnly, setOneVoteOnly,goldenValue} = useContext(AppContext);
  const { user } = useContext(UserContext);
  let params = useParams();
  const [symbol1, symbol2] = (params?.id || "").split("-");
  const { userInfo } = useContext(UserContext);
  const [tenSecEndTime, setTenSecEndTime] = useState(0); 
  const [tooltipShow, setTooltipShow] = useState(false); 
  

  // console.log(!hideButton.includes(selectedTimeFrame),"selectedTimeFrame Now")

  // console.log(texts?.tooltip, "all Text")

  const hideGoldenVoteTime = getGMTTimestampWithValue(goldenValue?.goldenVoteStoppedAt)
  const currentTimeStamp = moment().valueOf();  

  const targetDate = getNewGoldVtoe(goldenValue?.goldenVoteAvailableAt || 18);

  // Custom renderer for countdown
  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      return <span>It's 18:00 UTC now!</span>;
    } else {
      return (
        <span>
          {days > 0 && `${days}d`} {hours>0 &&`${hours}h`} {minutes}m {seconds}s
        </span>
      );
    }
  };

  useEffect(() => {
    if(userInfo?.uid){
      getGoldenReward(userInfo?.uid,setTenSecEndTime);
    }

    // @ts-ignore
  }, [userInfo?.voteValue, userInfo?.rewardStatistics?.extraVote]);
// @ts-ignore
const isOld = moment(userInfo && userInfo?.createdAt).isBefore(moment().subtract(3, "weeks")); 
// @ts-ignore
  const CheckBoth = isOld==false ? userInfo?.rewardStatistics?.total < 2?true:false:false   

console.log(CheckBoth , userInfo?.miningPass?.isActive == true , tenSecEndTime > moment().valueOf(),"CheckBoth")
  return (
    <Form
      className='mt-3'
      id={id}
      noValidate={true}
      onSubmit={(e) => {
        e.preventDefault();
        const voteValue = userInfo?.rewardStatistics?.extraVote + userInfo?.voteValue
        if (voteValue > 0 &&oneVoteOnly == false) {
          if(selectedTimeFrame ==0){
            console.log("yes i am clickinggggg 112233")
            if(CheckBoth || userInfo?.miningPass?.isActive == true || tenSecEndTime > moment().valueOf()){
              setOneVoteOnly(true);
              console.log("yes i am clickinggggg 112233")
              submit(); 
            }else{
              console.log("Please buy mining pass")
            }
          }else{
            setOneVoteOnly(true);
            console.log("yes i am clickinggggg 112233")
            submit();
          }
        }
      }}
      style={{ maxWidth: '450px', margin: '0 auto' }}
    >      
      <div className="mt-4" style={{ marginLeft: symbol2 ? '' : '24px', marginRight: symbol2 ? '' : '24px' }}>
        <SelectTimeframes
          {...{
            selected: selectedTimeFrame,
            timeframes,
            selectTimeframe: (timeframe) => {
              setSelectedTimeFrame(timeframe.index);
            },
            title: texts.selectTimeFrame,
            selectedTimeFrameArray: selectedTimeFrameArray,
            cssDegree,
            votePrice,
            votedDetails,
            hideButton,
            setHideButton,
            setpopUpOpen,
            vote,
            disableVoteButton
          }}
        />

      </div> 
      {selectedTimeFrame ==4 && goldenValue?.isActive==false ?
      <>
      <div className='mt-4 pt-2'>
      <div 
          className="text-center"
          style={{
            color: "#6352e9",
            fontSize:"13px"
          }}
        ><p>Next golden vote will be available in <Countdown date={targetDate} renderer={renderer} />.</p> </div>
        </div>
      </>:
        <>
        <div className='mt-4 pt-2'>
        {/* @ts-ignore */}
        <div 
          className="text-center"
          style={{
            color: "#6352e9",
            fontSize:"13px"
          }}
        ><p>Who gets your vote?</p> 
         <div style={{ position: 'relative',zIndex:"199999" }}>
          <I className="bi bi-info-circle"

            style={{
              right:window.screen.width > 767 ?"140px":"94px",
            }}
            onMouseDown={(e) => { 
              e.stopPropagation();
              setTooltipShow(false)
            }}
            onMouseUp={(e) => {   
              e.stopPropagation();
              setTooltipShow(true)
            }}
            onMouseEnter={(e) => {
              e.stopPropagation();
              setTooltipShow(true)
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setTooltipShow(false)
            }}
          ></I>

          {
            tooltipShow &&
            <div
              style={{
                display: "relative"
              }}
            >
              <div className="newtooltip"
                style={{
                  zIndex: "12",
                  // marginLeft: "16%",
                  // marginTop:"0%",
                  width: `${window.screen.width > 767 ? "80%" : "80%"}`,
                  marginLeft: `${window.screen.width > 767 ? "100px" : "20%"}`,
                  marginTop: `${window.screen.width > 767 ? "2%" : "2%"}`,
                }}
              >
                {/* <p>User type level</p> */}
                <p className="mt-3 lh-base"
                  style={{
                    textAlign: "left"
                  }}
                  >
                    <p className="mb-2"><strong>🌀 "In the Zone" Boost</strong></p>
                      3 high-impact votes = 15% CMP boost on the next vote.
                      Stays active until 2 non-high-impact votes in a row.

                      <p className="mb-2 mt-2"><strong>🔥 "On Fire" Boost</strong></p>
                      5 high-impact votes = 40% CMP boost on every vote.
                      Expires after 2 non-high-impact votes in a row.

                      <p className="mt-2"><strong>💡 Tip: Keep the streak alive for bigger rewards!</strong></p>
                  </p>                
              </div>
            </div>
          }

        </div> 
        </div>
         
        <div className='mb-3'>
          {/* <Title>{texts.yourVote}</Title> */}
        </div>        
        <div className="">
          <CPVote
            {...{
              selectedOption,
              setSelectedOption,  
              selectedTimeFrame           
            }}
            width={width || 266}
            // disabled={!canVote || disabled}
            disabled={!!!user}
            disabledText={texts.tooltip}
            options={[
              {
                icon:
                  typeof option1.image === "string" ? (
                    <img src={option1.image} alt={option1.alt} />
                  ) : (
                    <>
                      {/* <p>vote {option1.image} BEAR</p> */}
                      {/* @ts-ignore */}
                      {option1?.buttonText ? <p>{option1?.buttonText[0]} {option1.image} {option1?.buttonText[1]}</p> : <> Vote<p>{option1.image}</p> </>}
                    </>
                  ),
                buttonProps: {
                  children: undefined,
                },
              },
              {
                icon:
                  typeof option2.image === "string" ? (
                    <img src={option2.image} alt={option2.alt} />
                  ) : (
                    <>
                      {" "}                      
                      {/* @ts-ignore */}
                      {option2?.buttonText ? <p>{option2?.buttonText[0]} {option2.image} {option2?.buttonText[1]}</p> : <> Vote<p>{option2.image}</p> </>}
                    </>
                  ),
                buttonProps: {
                  children: undefined,
                },
              },
            ]}
          >
            {children}
          </CPVote>
        </div>
      </div > 
      </>
      }
    </Form >
  );
};

export default VoteForm;
