// ScrollToTopButton.jsx
import React, { useEffect, useState } from 'react';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  useEffect(() => {
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 200);
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (    
    <>
      {isVisible &&<button
        onClick={scrollToTop}
        style={{
          position: 'fixed',
          width:"50px",
          height:"50px",
          bottom:'20px',
          right: '20px',
          backgroundColor: '#333',
          color: '#fff',
          border: 'none',
          borderRadius: '50%',
          padding: '12px',
          cursor: 'pointer',
          fontSize: '20px',
          zIndex: 1000,
        }}
        aria-label="Scroll to top"
      >
        ↑
      </button>}
    
    </>
  );
};

export default ScrollToTopButton;
