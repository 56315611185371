import React, { useEffect, useState } from 'react'
import './ExtraVoteAnimation.css'
import starYellow from '../../assets/images/star-yellow.png'

const ExtraVoteAnimation = ({countNumber,type,MiningPass}: {countNumber: number,type:string,MiningPass?:any}) => {
  const percentageMap: Record<number, number> = {
    2: 50,
    3: 100,
    4: 150,
    5: 200,
  };
  const [miningPassActive , setMiningPassActive] =useState(false)
  useEffect(()=>{
    if(MiningPass && countNumber >1){      
      setTimeout(() => {
        if(countNumber > 1){
          setMiningPassActive(true)
        }
      }, 4000);
    }
    if(MiningPass && countNumber <2){
      setMiningPassActive(true)
    }

  },[MiningPass,countNumber])


  console.log(MiningPass,miningPassActive,"miningPassActive")
  return (
    <div className='extra-vote-animation'>
      <div className="container-extra-vote">
        <div className="sun-wrapper">
            <div className="rays">
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
                <div className="ray"></div>
            </div>
            <div className="circle">
                <img src={starYellow}/>
                {/* <img src="../../assets/images/star-brown.png" /> */}
            </div>
            {miningPassActive && MiningPass && type=="mega" ? <div className="text">
              <p>5X</p>
              <p>Extra</p>
            </div>:
              <div className="text"><p>Upto</p>
              <p>{percentageMap[countNumber]}%</p></div>
            }            
        </div>
    </div>
    </div>
  )
}

export default ExtraVoteAnimation
