/** @format */

import { Button, Container, Form, Modal, Navbar } from "react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import UserContext, { getUserInfo } from "../Contexts/User";
import { Logout } from "../common/models/Login";
import AppContext from "../Contexts/AppContext";
import ContentContext from "../Contexts/ContentContext";
import Menu, { convertPageToMenuItem } from "./Menu";
import { ProfileTabs } from "../Pages/Profile";
import Logo, { Size } from "./Atoms/Logo";
import styled, { css } from "styled-components";
import { isV1 } from "./App/App";
import { useWindowSize } from "../hooks/useWindowSize";
import UserCard from "./Profile/UserCard";
import ImageTabs from "./Profile/ImageTabs";
import Avatars, { AvatarType, defaultAvatar } from "../assets/avatars/Avatars";
import { translate, useTranslation } from "../common/models/Dictionary";
import BigLogo from "../assets/svg/logoiconx2.png";
import BigLogo2 from "../assets/svg/logoiconxbig2.png";
import giftIcon from "../assets/images/gift-icon-head.png";
import ManagersContext from "../Contexts/ManagersContext";
import Countdown from "react-countdown";
import { getFollowerInfo } from "../Contexts/FollowersInfo";
import coin_bgVET from "../assets/images/coin_bgVET.png";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { db, firestore, functions } from "../firebase";
import firebase from "firebase/compat/app";
import AddFollower from "./icons/AddFollower";
import Following from "./icons/Following";
import CoinsContext, { Leader, follow } from "../Contexts/CoinsContext";
import { toFollow } from "../common/models/User";
import "./styles.css";
import {
  claimRewardSound,
  handleExtraVote,
  handleSoundWinCmp,
} from "../common/utils/SoundClick";
import CountUp from "react-countup";
import { Other } from "Pages/SingleCoin";
import { VoteContext } from "Contexts/VoteProvider";
import Spinner from "./Spinner";
import useSound from "use-sound";
// @ts-ignore
import buttonClick from "../assets/sounds/buttonClick.mp3";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import HeaderProgressbar from "./HeaderProgressbar";
import { CurrentCMPContext } from "Contexts/CurrentCMP";
import FollowerProfile from "Pages/FollowerProfile";
import { getUsers, getLeaderUsersByIds } from "./FollowerProfile/FwFollow";
import SpinerHeader from "./SpinerHeader";
import axios from "axios";
import GoldenSpinerHeader from "./GoldenSpinerHeader";
import { Buttons } from "./Atoms/Button/Button";
import moment from "moment";

enum EventKeys {
  LOGIN = "login",
  LOGOUT = "logout",
  SIGNUP = "signup",
  ABOUT = "about",
  PROFILE = "profile",
  VOTES = "votes",
  NOTIFICATIONS = "notifications",
  PASSWORD = "password",
  EDIT = "info",
  POOL_MINING = "pool",  
  SHARE = "share",
  FOLLOWERS = "followers",
  Gallery = "Album",
}

export const Title = styled.div`
  font: var(--font-style-normal) normal var(--font-weight-normal) 20px/18px
    var(--font-family-poppins);
  letter-spacing: var(--character-spacing-0);
  color: var(--white);
  text-align: left;
  opacity: 1;
  margin: 0;

  & h1 {
    text-align: center;
  }
`;

export const HeaderCenter = styled.div`
  background: white;
  color: #3712B3;
  width: 58%;
  height: 35px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 120px;
   border-radius 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const HeaderCenterMob = styled.div` 
  background:white;
  color:#3712B3;
  width: 85%;
  height: 35px;
  
  margin-left:30px;
  border-radius 50px;
  display: flex;
  justify-content:space-around;
  // align-items: center;

  @media (min-width: 768px) and (max-width: 1000px) {
		width:12rem
	}
	@media (max-width: 767px) {
		width:12rem
	}
`;
export const MemberText = styled.span`
  text-transform: uppercase;
  padding: 2px 10px 2px 10px;
  background: #d4d0f3;
  color: #6352e8;
  border-radius: 10px;
  font-size: 9px;
`;

export const PlusButton = styled.div`
  width: 27px;
  height: 27px;
  background: #6352e8;
  color: white;
  border-radius: 50px;
  padding-top: 3px;
  font-size: 15px;
  cursor: pointer;
`;
export const PlusButtonMob = styled.div`
  width: 20px;
  height: 20px;
  background: #6352e8;
  color: white;
  border-radius: 50px;
  padding: 1px 5px;
  margin-top: 0px;
  margin-right: 20px;
  font-size: 13px;
  cursor: pointer;
`;

type ZoomProps = {
  showReward?: number;
  inOutReward?: number;
};
const ZoomCss = css`
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2200;
  //   transition: opacity .3s;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  transition: all 3s ease;
`;
//   transform: ${window.screen.width > 767 ? "scale(3)" : "scale(1.5)"};
//   transformOrigin:${window.screen.width > 767 ?"35% 50%" :"50% 90%"};
// left: 50%;
// transform: translate(-50%, -20%);

const ForZoom = styled.div`
  ${(props: ZoomProps) =>
    `${props.showReward == 2 && props.inOutReward == 2 ? ZoomCss : ""}`}
`;

const I = styled.i`
  border-radius: 50%;
  font-size: 13px;
  position: absolute;
  font-weight: 300;
  //   top:-27px;
  //   left:180px;
  //   color: #6352e8;
  color: white;
  //   width: 16px;
  //   height: 16px;
  text-align: center;
`;

export const OuterContainer = styled.div`
  background: ${window.screen.width < 979 ? "var(--color-d4d0f3)" : ""};
  position: relative;
  z-index: 0;
  padding-bottom: ${window.screen.width < 979 ? "100px" : ""};
`;

// export const CardContainer = styled.div`
//   ${Gradient2};
//   height: 127px;
//   padding: 0;
//   box-shadow: 0 3px 6px #00000029;
//   border-radius: 0 0 87px 0;
// `;

const MenuContainer = styled(Menu)`
  border: 1px solid red;
`;

const Header = ({
  title,
  logo = true,
  pathname,
  remainingTimer,
  setMfaLogin,
}: {
  title?: React.ReactNode;
  logo?: boolean;
  pathname: string;
  remainingTimer: number;
  setMfaLogin: any;
}) => {
  const navigate = useNavigate();
  const { user, setUser, userInfo } = useContext(UserContext);
  const [mounted, setMounted] = useState(false);
  const { width } = useWindowSize();
  const desktop = width && width > 979;
  const { pages } = useContext(ContentContext);
  const {
    voteRules,
    login,
    showReward,
    setShowReward,
    headerExtraVote,
    setHeaderExtraVote,
    inOutReward,
    setInOutReward,
    afterVotePopup,
    setAfterVotePopup,
    setvoteNumberEnd,
    rewardExtraVote,
    languages,
    setLang,
    setLogin,
    setSignup,
    setMenuOpen,
    showMenubar,
    avatarImage,
    getSpinData,
    megaSpinData,
    freeSpinData,
    contDownValue,
    spinAccessLimit, 
    silverSpinData                    
  } = useContext(AppContext);
  const followerUserId = localStorage.getItem("followerId");
  const translate = useTranslation();
  const [voteNumber, setVoteNumber] = useState(0);

  const [votingTimer, setVotingTimer] = useState(0);
  const [followerInfo, setFollowerInfo] = useState<any>();
  const [followUnfollow, setFollowUnfollow] = useState<any>(false);

  const [textBlink, setTextBlink] = useState<any>(false);
  const { leaders } = useContext(CoinsContext);
  const [userRank, setUserRank] = React.useState(0);
  const [cpmValueShow, setCpmValueShow] = React.useState(0);
  const [deactivateApi, setDeactivateApi] = useState<any>(false);
  const [miningPassEnd, setMiningPassEnd] = useState<any>(false);

  var urlName = window.location.pathname.split("/");
  const followerPage = urlName.includes("followerProfile");
  const votingboosterPage = urlName.includes("votingbooster");
  const [tooltipShow, setTooltipShow] = React.useState(false);
  const [upgradeSettingMining, setUpgradeSettingMining] = useState(
    [
      {
          "amount": 14.99,
          "Days": 7,
      },
      
  ]
  )
  const MyPath = window.location.pathname;
  const currentCMP = useContext(CurrentCMPContext);
  const currentCMPDiff = Math.floor(
    (userInfo?.voteStatistics?.score || 0) / 100
  );
  const prevCMPDiff = Math.floor(
    ((userInfo?.voteStatistics?.score || 0) - currentCMP) / 100
  );
  useEffect(()=>{
    // const score =(userInfo?.voteStatistics?.score || 0) - (userInfo?.rewardStatistics?.total || 0) * 100;
    const score =(userInfo?.voteStatistics?.score || 0) - ((userInfo?.rewardStatistics?.total || 0) * 100);
    setCpmValueShow(score)

  },[userInfo?.voteStatistics?.score])
  const score =
    (userInfo?.voteStatistics?.score || 0) -
    (userInfo?.rewardStatistics?.total || 0) * 100;
  const remainingCMP =
    currentCMP > 0 &&
    currentCMPDiff > prevCMPDiff &&
    (userInfo?.voteStatistics?.score || 0) > 0
      ? score
      : score;
  // const remainingCMP = ((currentCMP > 0 && currentCMPDiff > prevCMPDiff && (userInfo?.voteStatistics?.score || 0) > 0) ? 100 : score);
  const remainingReward = (userInfo?.rewardStatistics?.total || 0) - (userInfo?.rewardStatistics?.claimed || 0);
  const voteDetails = useContext(VoteContext);
  const [handleSoundClick] = useSound(buttonClick);
  const prevCountRef = useRef(voteNumber);


  const getFollowerData = async () => {
    const userCollection = collection(firestore, "users");
    try {
      const q = query(userCollection, where("uid", "==", followerUserId));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {        
        setFollowerInfo(doc.data());
      });
    } catch (error) {
      console.error("Error fetching follower info:", error);
    }
  };

  const getmyChildernUser = () => {
    if (!userInfo) return;
    let getChilData = getLeaderUsersByIds({ userIds: userInfo?.children })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return [];
      });
    return getChilData;
  };

  useEffect(() => {
    if (followerUserId) {
      getFollowerData();
      const ourUser = leaders.filter((item) => item?.userId == followerUserId);
      console.log(ourUser, "ouerdata");
    
      if (ourUser && ourUser[0]?.rank) {
        setUserRank(ourUser[0]?.rank);
      }
    }
  }, [followerUserId]);

  useEffect(() => {
    if (userInfo?.uid) {      
      getSpinData(userInfo?.uid)
      fetchUpgradeSettingData()
    }
    return () => {};
  }, [userInfo?.uid]);

  useEffect(() => {
    // @ts-ignore
    if (userInfo?.leader?.includes(followerUserId)) {
      setFollowUnfollow(true);
    } else {
      setFollowUnfollow(false);
    }
  }, [followerUserId, userInfo]);

  useEffect(() => {
    // @ts-ignore
    if (
      Number(userInfo?.voteValue) == 0 &&
      user?.uid &&
      !login &&
      !userInfo?.lastVoteTime
    ) {
      console.log("yes i am working", Date.now());
      const liveValue = Date.now();
      const userDocRef = doc(firestore, "users", user?.uid);
      try {
        setDoc(userDocRef, { lastVoteTime: liveValue }, { merge: true });
        console.log("User data updated successfully!");
      } catch (error) {
        console.error("Error updating user data:", error);
      }
    }
  }, [voteNumber, /* votingTimer, */ voteDetails]);

  useEffect(() => {
    setVotingTimer(remainingTimer);
  }, [remainingTimer]);
  useEffect(() => {
    if (pages) {
      setMounted(true);
    }
  }, [pages]);

  useEffect(() => {
    setVoteNumber(
      Number(userInfo?.voteValue || 0) +
      Number(userInfo?.rewardStatistics?.extraVote || 0)
      // -
      //   (headerExtraVote.collect == false ? headerExtraVote?.vote : 0)
    );
    // @ts-ignore
    setvoteNumberEnd(Number(userInfo?.voteValue));
    // @ts-ignore
    prevCountRef.current = voteNumber;
    // console.log('votenumber',voteNumber, Number(voted))
    // @ts-ignore
  }, [
    userInfo?.voteValue,
    userInfo?.rewardStatistics?.extraVote,
    headerExtraVote?.vote,
  ]);
  // console.log(voteRules?.maxVotes, userInfo?.rewardStatistics?.extraVote, votesLast24Hours, headerExtraVote ,"allvotetype")
  // console.log(headerExtraVote, voteNumber, "headerExtraVote")

  const onSelect = (eventKey: string | null) => {
    // handleSoundClick()
    const auth = getAuth();

    switch (eventKey) {
      case EventKeys.LOGIN:
        setLogin(true);
        setSignup(false);
        break;
      case EventKeys.SIGNUP:
        setLogin(true);
        setSignup(true);
        break;
      case EventKeys.LOGOUT:
        signOut(auth)
          .then((res) => {
            localStorage.removeItem("activeSessionId")
            Logout(setUser);
            navigate("/");
            setLogin(true);
            // console.log("i am working error")
            localStorage.removeItem("userId");
            localStorage.clear();
          })
          .catch((error) => {
            navigate("/");
            setLogin(true);
            localStorage.removeItem("userId");
            localStorage.clear();
            const errorMessage = error.message;
          });
        break;
      case EventKeys.EDIT:
        navigate(ProfileTabs.profile + "/" + ProfileTabs.edit, {
          replace: true,
        });
        break;
      case EventKeys.POOL_MINING:
        navigate(ProfileTabs.profile + "/" + ProfileTabs.mine, {
          replace: true,
        });
        break;
      case EventKeys.SHARE:
        navigate(ProfileTabs.profile + "/" + ProfileTabs.share, {
          replace: true,
        });
        break;
      case EventKeys.FOLLOWERS:
        navigate(ProfileTabs.profile + "/" + ProfileTabs.followers, {
          replace: true,
        });
        break;
      case EventKeys.PASSWORD:
        navigate(ProfileTabs.profile + "/" + ProfileTabs.password, {
          replace: true,
        });
        break;
      case EventKeys.Gallery:
        navigate(ProfileTabs.profile + "/" + ProfileTabs.ProfileNftGallery, {
          replace: true,
        });
        break;
      case EventKeys.NOTIFICATIONS:
        navigate(ProfileTabs.profile + "/" + ProfileTabs.notifications, {
          replace: true,
        });
        break;
      case EventKeys.VOTES:
        navigate(ProfileTabs.profile + "/" + ProfileTabs.votes, {
          replace: true,
        });
        break;
      default:
        if (eventKey && languages.includes(eventKey)) {
          setLang(eventKey);
        }
    }

    setMenuOpen(false);
  };

  const handleClose = () => {
    setAfterVotePopup(false);
  };

  const fetchUpgradeSettingData = async () => {
    try {
      // Access the 'uservalue' document in the 'user' collection
      const docRef = doc(db, 'settings', 'upgradeSettings');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // Retrieve the 'userData' field        
        setUpgradeSettingMining(docSnap.data()?.MINING_PRICE_OPTIONS);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const buyMiningPass = (amount: any, VTEAmount: any) => {        
      let payvalue = [amount,"MININGPASS",0, VTEAmount]
      let PayValuestring = JSON.stringify(payvalue)
      console.log(PayValuestring,"PayValuestring")
      localStorage.setItem("PayAmount", PayValuestring);
      navigate("/votepayment")
      setMiningPassEnd(false);
  }
  // [14.99,"MININGPASS",0,2]
  // [0.00001,"MININGPASS",0,1]
  const deactivateMiningPass = httpsCallable(functions, "deactivateMiningPass");
  console.log(userInfo?.miningPass?.expiryDate.seconds > moment().unix(),"userInfo?.miningPass")

  console.log(upgradeSettingMining,"upgradeSettingMining")

  const handleMiningTimeComplete = () => {
    if (!deactivateApi) {
      setDeactivateApi(true)
      deactivateMiningPass({ userId: userInfo?.uid })
        .then(() => {
          setMiningPassEnd(true);
          setDeactivateApi(false) // Reset for potential future use
        })
        .catch(() => {
          setMiningPassEnd(false);
          setDeactivateApi(false) // Reset
        });
    }
  };

console.log(freeSpinData?.freeSpinAvailable <1 , (!freeSpinData?.rewardedFreeSpin || freeSpinData?.rewardedFreeSpin < 1) , megaSpinData , voteNumber != 0,"checkspin value")
  // console.log(followerInfo,"followerInfo")
  return (
    <>
    {userInfo?.uid && userInfo?.miningPass?.isActive &&
    <Countdown
    className="d-none"
          date={
            new Date(
              userInfo?.miningPass?.expiryDate.seconds * 1000 +
                userInfo?.miningPass?.expiryDate.nanoseconds / 1000000
            )
          }
          onComplete={handleMiningTimeComplete} // Handle completion      
      />
    }
      {userInfo ? (
        <MenuContainer
          setMfaLogin={setMfaLogin}
          pathname={pathname}
          onSelect={onSelect}
          items={[
            {
              href: "/",
              label: "Home",
            },
            {
              href: "/pairs",
              label: "Pairs Vote",
            },
            {
              href: "/coins",
              label: "Coin Vote",
            },
            // {
            //   href: "/pairs",
            //   label: "Pairs Vote",
            // },
            {
              href: "/influencers",
              label: "Top Influencers",
            },
            {
              href: "/WallOfFame",
              label: "Wall Of Fame",
            },
            {
              label: "",
            },
            user && {              
              href: "/votingbooster",
              label: "Buy Vote",
            },
            user && {
              eventKey: EventKeys.POOL_MINING,
              label: "My Account",
            },

            user && {
              eventKey: EventKeys.EDIT,
              label: "My Profile",
            },
            user && {
              eventKey: EventKeys.Gallery,
              label: "My Album",
            },
            user && {
              eventKey: EventKeys.NOTIFICATIONS,
              label: "Notifications",
            },

            {
              label: "",
            },
            {
              label: "",
            },
            ...(pages || []).map(convertPageToMenuItem),
            {
              label: "-",
            },
            !user && {
              eventKey: EventKeys.LOGIN,
              label: "Login",
            },
            user && {
              eventKey: EventKeys.LOGOUT,
              label: "Logout",
            },
            !user && {
              eventKey: EventKeys.SIGNUP,
              label: "Join the parliament",
            },
          ].map((i) => (i ? i : undefined))}
        >
          {/* {for center modile size} */}

          {!desktop ? (
            <div className="" style={{ width: "80%" }}>
              <div className="d-flex w-100  mobileHeader">
                <ForZoom {...{ showReward, inOutReward }} className="w-100">
                  {user?.uid && !login ? (
                    <div
                      className="d-flex mobile-centered-element"
                      style={{
                        position: "relative",
                        margin: "0 auto",
                        width: `${
                          showReward == 2 && inOutReward == 2
                            ? "fit-content"
                            : "fit-content"
                        }`,
                        transform: `${
                          showReward == 2 && inOutReward == 2
                            ? "scale(1.3)"
                            : ""
                        }`,
                        transformOrigin: `${
                          showReward == 2 && inOutReward == 2 ? "40% 0%" : ""
                        }`,
                        transition: `${
                          showReward == 2 && inOutReward == 2
                            ? "transform 3s ease"
                            : ""
                        }`,
                        left: `${
                          window.screen.width < 575 &&
                          followerPage &&
                          followerInfo != ""
                            ? "0px"
                            : ""
                        }`,
                        // transformOrigin: `${window.screen.width > 767 ? "60% 0%" : "40% 0%"}`,
                      }}
                    >
                      <div
                        className=""
                        onClick={() => {
                          if (!showMenubar && !followerPage)
                            navigate("/profile/wallet");
                        }}
                        style={{
                          position: "absolute",
                          marginTop: "7px",
                          cursor: "pointer",
                          left: "0px",
                        }}
                      >
                        <Avatars
                          type={
                            followerPage && followerInfo != ""
                              ? followerInfo?.avatar ||
                                (defaultAvatar as AvatarType)
                              : ((avatarImage ||
                                  userInfo?.avatar) as AvatarType)
                          }
                          style={{
                            width: "55px",
                            height: "55px",
                            // border: "1px solid #6352E8",
                            // @ts-ignore
                            boxShadow: `${
                              userInfo?.isUserUpgraded && (userInfo?.miningPass?.expiryDate.seconds > moment().unix()) && !followerPage?"1px 0px 10px 4px #ffd700":
                              (userInfo?.isUserUpgraded && !followerPage) ||
                              (followerInfo?.isUserUpgraded && followerPage)                              
                                ? "1px 0px 5px 2px #ffd700"
                                :(userInfo?.miningPass?.expiryDate.seconds > moment().unix()) && !followerPage?"0 0 12px 4px #18A2FF"
                                : "1px 0px 5px #6352E8"
                            }`,
                            background: `${
                              userInfo?.isUserUpgraded && (userInfo?.miningPass?.expiryDate.seconds > moment().unix()) && !followerPage?"linear-gradient(to top, #18A2FF, #FAE481)":
                              (userInfo?.isUserUpgraded && !followerPage) ||
                              (followerInfo?.isUserUpgraded && followerPage) 
                              ? "#F3D87A"
                                :(userInfo?.miningPass?.expiryDate.seconds > moment().unix()) && !followerPage?"#18A2FF"
                                : "#6352E8"
                            }`,
                          }}
                        />
                      </div>
                      <div className="w-100 mt-3" style={{ marginLeft: "0px" }}>
                        <HeaderCenterMob className="align-items-center">
                          {/* <div></div> */}
                          <div
                            className="mt-0"
                            style={{
                              marginLeft: `${voteNumber == 0?"20px":"25px"}`,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {followerPage && followerInfo != "" ? (
                              <span
                                style={{
                                  marginLeft: `${
                                    followerInfo?.displayName?.length < 12
                                      ? "15px"
                                      : "4px"
                                  }`,
                                }}
                              >
                                {followerInfo?.displayName}
                              </span>
                            ) : !voteNumber &&
                              userInfo.rewardStatistics?.extraVote == 0 &&
                                contDownValue &&
                                !!new Date(contDownValue).getDate() ? (
                              // @ts-ignore */
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginTop: "1px",
                                  lineHeight: "90%",
                                  textAlign: "center",
                                }}
                              >
                                {/* @ts-ignore */}
                                    {freeSpinData?.freeSpinAvailable == 0 && megaSpinData?.megaSpinAvailable == 0? <Countdown
                                  daysInHours
                                  zeroPadTime={2}
                                      date={contDownValue}
                                  renderer={({
                                    hours,
                                    minutes,
                                    seconds,
                                    completed,
                                  }) => {
                                    if (completed) {
                                      getSpinData(userInfo?.uid)
                                    } 
                                    return (
                                      <span
                                        style={{
                                          color: "#6352e8",
                                          fontSize:
                                            window.screen.width <= 340
                                              ? "0.7889em"
                                              : "10px",
                                          fontWeight: 100,
                                          lineHeight: "10%",
                                        }}
                                      >
                                        {/* Wait {" "} */}
                                        {/* {Number(voteRules?.maxVotes)} Votes in{" "} */}
                                        {"Free spin in"} {" "}
                                        {hours < 1 ? null : `${hours}:`}
                                        {minutes < 10 ? `0${minutes}` : minutes}
                                        :
                                        {seconds < 10 ? `0${seconds}` : seconds} 
                                        {/* {` left buy mega spin`} */}
                                        {/* for {Number(voteRules?.maxVotes)} votes <br /> or buy extra votes now. */}
                                      </span>
                                    );
                                  }}
                                    /> :
                                      <span >{userInfo.voteValue == 0 ? "spin available" : ""}</span>
                                }
                              </div>
                            ) : (
                              <span
                                style={{
                                  color: "#6352E8",
                                  marginLeft: "17px",
                                  fontSize:
                                    window.screen.width <= 340
                                      ? "0.7889em"
                                      : "10px",
                                }}
                              >
                                {
                                  MyPath == "/profile/mine" &&
                                  inOutReward === 2 ? (
                                    <CountUp
                                      useEasing={false}
                                      className={"HeaderText "}
                                      start={
                                        voteNumber == 0 ? 0 : voteNumber || 0
                                      }
                                      end={
                                        (voteNumber || 0) +
                                        (headerExtraVote?.vote || 0)
                                      }
                                      duration={
                                        rewardExtraVote < 10
                                          ? rewardExtraVote
                                          : 10
                                      }
                                      delay={2}
                                      onStart={() => {
                                        // handleExtraVote.play()
                                        // setTimeout(() => {
                                        // 	setTextBlink(true)
                                        // }, 3000);
                                      }}
                                      onEnd={() => {
                                        setTextBlink(false);
                                        handleExtraVote.pause();
                                        setInOutReward((prev: number) => {
                                          // return prev == 2 ? 3 : prev
                                          claimRewardSound.play();
                                          return 3;
                                        });
                                        if (headerExtraVote != 0) {
                                          setShowReward((prev: number) => {
                                            return 3;
                                          });
                                        }
                                        setHeaderExtraVote({
                                          vote: 0,
                                          collect: false,
                                        });
                                        // setHeaderExtraVote((prev: number) => {
                                        // 	if (prev != 0) {
                                        // 		setShowReward((prev: number) => {
                                        // 			// return prev == 2 ? 3 : prev
                                        // 			return 3;
                                        // 		})
                                        // 	}
                                        // 	return prev
                                        // })
                                      }}
                                    />
                                  ) : (
                                          // Number(voteNumber && voteNumber)
                                          <>                                          
                                            {voteNumber == 0 ? <span style={{ fontSize: "10px" }}>{userInfo.voteValue == 0 ? "spin available" : ""}</span> : Number(voteNumber && voteNumber)}
                                          </>
                                  )
                                  // + (headerExtraVote?.collect ? headerExtraVote?.vote : 0)
                                }{" "}
                                    {voteNumber != 0 &&"votes left"}
                              </span>
                            )}
                          </div>
                          {followerPage && followerInfo != "" ? (
                            <Form.Check.Label
                              style={{
                                cursor: "pointer",
                                border: "1px solid rgb(55, 18, 179)",
                                borderRadius: "25px",
                                margin: "0 0 3px 3px",
                                width: "27px",
                                height: "27px",
                                display: "flex",
                                justifyContent: " center",
                                alignItems: "center",
                              }}
                              className="mt-1"
                              bsPrefix="label"
                              onClick={async () => {
                                handleSoundClick();
                                setFollowUnfollow(!followUnfollow);
                                // console.log('folower',followerInfo)
                                const ll = leaders.find(
                                  (l) => l.userId === followerInfo?.uid
                                );
                                if (user && ll) {
                                  await follow(ll, user, !followUnfollow);
                                }
                                // @ts-ignore
                                //  await follow(followerInfo , user, checkFollow )
                              }}
                            >
                              {/* {checked && iconOn}
                              								{!checked && iconOff} */}
                              {followUnfollow == true ? (
                                <Following />
                              ) : (
                                <AddFollower />
                              )}
                            </Form.Check.Label>
                          ) : (
                              <div
                                onClick={() => {                                  
                                    window.scrollTo({ top: 0, behavior: 'smooth' });                                  
                              }}
                              >                                                                                            
                                { freeSpinData?.freeSpinAvailable < 1 &&  (!silverSpinData?.silverSpinAvailable || silverSpinData?.silverSpinAvailable < 1) && megaSpinData?.megaSpinAvailable < 1 && voteNumber != 0 ?                            
                                  <div style={{
                                    width: "33px",
                                    height: "33px",
                                    marginTop: "7px",
                                    marginRight: "22px",

                                  }}
                                    onClick={() => {
                                      handleSoundClick();
                                      navigate(`/wheel`)
                                  }}
                                  >
                                    {userInfo?.voteValue > spinAccessLimit?<GoldenSpinerHeader />:<>
                                    {Object.keys(freeSpinData).includes('spinnerEnabled') && Object.keys(freeSpinData).includes('votesReceivedFromFirst4Spins') && freeSpinData.totalSpinsUsed < 4 ? <SpinerHeader />: <GoldenSpinerHeader />}
                                    </>}
                                  </div>
                                  : freeSpinData?.freeSpinAvailable < 1 && voteNumber == 0 ?
                                    // <GoldenSpinerHeader />
                                    <div style={{
                                      width: "30px",
                                      height: "30px",
                                      marginTop: "7px",
                                      marginLeft: "-30px",

                                    }}
                                      onClick={() => {
                                        if (!showMenubar) {
                                          handleSoundClick();
                                          navigate("/wheel");
                                        }
                                      }}
                                    >
                                      {/* {(megaSpinData?.megaSpinAvailable ==1 || voteNumber == 0)  &&<GoldenSpinerHeader />} */}
                                      {<>
                                      {(megaSpinData?.megaSpinAvailable == 1 || voteNumber == 0) &&
                                        Object.keys(freeSpinData).includes('spinnerEnabled') && Object.keys(freeSpinData).includes('votesReceivedFromFirst4Spins') && freeSpinData.totalSpinsUsed < 4 ?<SpinerHeader /> : <GoldenSpinerHeader />
                                      }
                                      </>}
                                    </div>
                                    :
                                <div style={{
                                  width: "33px",
                                  height: "33px",
                                  marginTop: "7px",
                                  // marginLeft: "-20px",
                                  marginRight:"20px",

                                }}
                                  onClick={() => {
                                    if (!showMenubar) {                                      
                                      if (megaSpinData?.megaSpinAvailable > 0 || (megaSpinData?.megaSpinAvailable < 1 && (freeSpinData?.freeSpinAvailable > 0 || silverSpinData?.silverSpinAvailable > 0))) {
                                        navigate("/wheel");
                                      }
                                      handleSoundClick();
                                    }
                                  }}
                                >
                                      {/* {megaSpinData?.megaSpinAvailable > 0 && <GoldenSpinerHeader/>}
                                      {freeSpinData?.freeSpinAvailable > 0 && megaSpinData?.megaSpinAvailable < 1 && <SpinerHeader />} */}

                                    {silverSpinData?.silverSpinAvailable > 0?<SpinerHeader />:
                                    userInfo?.voteValue >= spinAccessLimit?<GoldenSpinerHeader />:<>                                    
                                      {Object.keys(freeSpinData).includes('spinnerEnabled') && Object.keys(freeSpinData).includes('votesReceivedFromFirst4Spins') && freeSpinData.totalSpinsUsed < 4                                        
                                        ?
                                        <SpinerHeader />
                                        : megaSpinData?.megaSpinAvailable > 0 ?
                                          <GoldenSpinerHeader /> : freeSpinData?.freeSpinAvailable > 0 && megaSpinData?.megaSpinAvailable < 1 && <SpinerHeader />}
                                    </>}
                                </div>
                                }
                                {userInfo?.uid && freeSpinData?.freeSpinAvailable < 1 && <Countdown date={contDownValue}
                                  renderer={({ hours, minutes, seconds, completed }) => {
                                    if (completed) {
                                      getSpinData(userInfo?.uid)
                                    }
                                    return (
                                      <>                                      
                                      </>
                                    );

                                  }}
                                />}
                              </div>
                          )}
                        </HeaderCenterMob>
                        <div
                          className="text-center"
                          style={{ marginLeft: "35px", marginTop: "3px" }}
                        >
                          {followerPage && followerInfo != "" ? (
                            ""
                          ) : (
                            <div
                              className="custom-circle-progress"
                              style={{
                                // marginRight: "-15px",
                                // marginTop:"-10px",
                                cursor: "pointer",
                                borderRadius: "50%",
                                border: "1px solid #160133",
                              }}
                              onClick={() => {
                                navigate("/profile/mine");
                              }}
                            >
                              <HeaderProgressbar
                                percentage={cpmValueShow?.toFixed(3) || 0}
                                remainingReward={remainingReward}
                              />
                            </div>
                          )}

                          {followerPage && followerInfo != "" ? (
                            ""
                          ) : (
                            <div
                              className="d-flex align-items-center shaped-btn-row"
                                style={{ margin: "4px 4px 4px 20px" }}
                            >
                              <button
                                className="btn-shaped me-1"
                                onClick={() => {
                                  navigate("/coins");
                                }}
                              >
                                COIN VOTE
                              </button>
                              <button
                                className="btn-shaped left-side"
                                onClick={() => {
                                  navigate("/pairs");
                                }}
                              >
                                PAIRS VOTE
                              </button>
                            </div>
                          )}
                          {userInfo?.uid && !followerPage && <div
                              className="d-flex align-items-center justify-content-center"
                                style={{ margin: "4px 4px 4px 1px",
                                  color:"white"
                                 }}
                            >
                            <img src={coin_bgVET} alt="" width="20px" />  
                            <p>{userInfo?.rewardStatistics?.diamonds} VTE</p>  
                          </div>}
                          <div className="d-none">
                            {followerPage && followerInfo != "" ? (
                              <></>
                            ) : (
                              <span
                                className={`${
                                  userInfo?.displayName ? "" : "mt-2"
                                } mb-1`}
                                style={{
                                  fontSize: "13px",
                                  width: "3.2rem",
                                  display: "inline-block",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {`${
                                  userInfo?.displayName /* && !userInfo.firstTimeLogin */
                                    ? userInfo?.displayName
                                    : ""
                                }`}
                              </span>
                            )}
                            <span style={{ marginLeft: "2px" }}>
                              {!!userInfo?.status?.name && !followerPage && (
                                <MemberText>
                                  {userInfo?.status?.name}
                                </MemberText>
                              )}
                            </span>
                          </div>
                          {!!followerInfo && (
                            <div className="d-flex justify-content-center ">
                              {!!followerInfo?.status?.name && followerPage && (
                                <MemberText>
                                  {followerInfo?.status?.name}
                                  {userRank > 0 ? <>&nbsp; #{userRank}</> : ""}
                                </MemberText>
                              )}
                              {!!followerInfo?.bio && followerPage && (
                                <>
                                  <div className="mx-2 ">
                                    <I
                                      className="bi bi-info-circle"
                                      onMouseDown={(e) => {
                                        setTooltipShow(false);
                                      }}
                                      onMouseUp={(e) => {
                                        setTooltipShow(true);
                                      }}
                                      onMouseEnter={() => setTooltipShow(true)}
                                      onMouseLeave={() => setTooltipShow(false)}
                                    ></I>
                                  </div>
                                  {tooltipShow && (
                                    <div
                                      style={{
                                        position: "fixed",
                                      }}
                                    >
                                      <div
                                        className="newtooltip"
                                        style={{
                                          // right: "0%",
                                          width: "270px",
                                          top: "25px",
                                          left: "-110px",
                                          // marginLeft: `${window.screen.width > 767 ? "2.50%" : ""}`,
                                          // marginTop: `${window.screen.width > 767 ? "10%" : "1%"}`,
                                          // zIndex:3000
                                        }}
                                      >
                                        {/* <p>Your CMP count</p> */}
                                        <p
                                          className="mt-1 lh-base"
                                          style={{
                                            textAlign: "left",
                                          }}
                                        >
                                          {followerInfo?.bio.toUpperCase()}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}

                          {followerPage && followerInfo?.isUserUpgraded && (
                            <div
                              style={{
                                textTransform: "uppercase",
                                padding: "2px 10px",
                                width: "100px",
                                background: "rgb(212, 208, 243)",
                                color: "rgb(99, 82, 232)",
                                borderRadius: "10px",
                                fontSize: "9px",
                                marginTop: "5px",
                                marginLeft: "35px",
                                boxShadow: "#FFD700 1px 0px 5px 2px",
                              }}
                            >
                              VERIFIED MINER
                            </div>
                          )}

                          {/* {(!!userInfo?.status?.name && !followerPage) && <MemberText
														style={{
															marginLeft: `${!userInfo?.isUserUpgraded && "-40px"}`
														}}
													>{userInfo?.status?.name}</MemberText>} */}

                          {!!userInfo?.status?.name &&
                            !followerPage &&
                            !userInfo?.isUserUpgraded && !(userInfo?.miningPass?.expiryDate.seconds > moment().unix()) &&  (
                              <MemberText
                                style={{
                                  background: "#ff9700",
                                  color: "black",
                                  wordBreak: "break-word",
                                }}
                                onClick={() => {
                                  if (!showMenubar) {
                                    handleSoundClick();
                                    navigate("/upgrade");
                                  }
                                }}
                              >
                              Earn more
                              </MemberText>
                            )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="w-100"></div>
                  )}
                </ForZoom>
                {showReward == 2 && window.screen.width < 767 && (
                  <div className="w-100"></div>
                )}
                {/* <div className='mt-2'>
							<Title
							 style={{ width: pathname === "/" ? "" : "" }}
							 onClick={handleSoundClick}
							 className=""
							>
								{mounted ? title : ""}
							</Title>
						</div> */}

                <div className="" style={{ width: "30%" }}>
                  <Navbar.Brand as={Link} to="/" className="ml-1"
                  style={{
                    marginTop:`${userInfo?.uid ?"18px":"0px"}`
                  }}
                  >
                    <img src={BigLogo} alt="" width="70px" />
                  </Navbar.Brand>
                </div>
              </div>
            </div>
          ) : logo ? (
            <div
              className=""
              style={{
                flexBasis: `${window.screen.width < 979 ? "80%" : "100%"}`,
                textAlign: "center",
                // transform: `${inOutReward == 2 && showReward == 2 ?"scale(1.5)":""}`,
                // transformOrigin: `${inOutReward == 2 && showReward == 2 ? "50% -10 %" : ""}`,
                // transition: `${backgrounHide ? "all 3s" : ""}`,
              }}
            >
              <div className="d-flex">
                <ForZoom
                  {...{ showReward, inOutReward }}
                  className="flex-fill d-flex" /* className="w-100" */
                  style={
                    {
                      // transform: `${showReward == 2 && inOutReward == 2 ? "scale(1.5)" : ""}`,
                      // transformOrigin: `${showReward == 2 && inOutReward == 2 ? "55% 0%" : ""}`,
                      // transformOrigin: `${window.screen.width > 767 ? "60% 0%" : "40% 0%"}`,
                    }
                  }
                >
                  {user?.uid && !login && (
                    <div
                      className="d-flex mx-auto w-auto"
                      style={{
                        left: `${
                          showReward == 2 && inOutReward == 2
                            ? "-85px"
                            : "-20px"
                        }`,
                        position: "relative",
                        height: "50px",
                        transform: `${
                          showReward == 2 && inOutReward == 2
                            ? "scale(1.5)"
                            : ""
                        }`,
                        transformOrigin: `${
                          showReward == 2 && inOutReward == 2 ? "50% 0%" : ""
                        }`,
                        transition: `${
                          showReward == 2 && inOutReward == 2
                            ? "transform 3s ease"
                            : ""
                        }`,
                      }}
                    >
                      <div
                        onClick={() => {
                          if (!showMenubar && !followerPage)
                            navigate("/profile/wallet");
                        }}
                        style={{
                          position: "absolute",
                          marginLeft: "83px",
                          cursor: "pointer",
                        }}
                      >
                        <Avatars
                          type={
                            followerPage && followerInfo != ""
                              ? followerInfo?.avatar ||
                                (defaultAvatar as AvatarType)
                              : ((avatarImage ||
                                  userInfo?.avatar) as AvatarType)
                          }
                          style={{
                            width: "60px",
                            height: "60px",
                            // @ts-ignore
                            boxShadow: `${
                              userInfo?.isUserUpgraded && (userInfo?.miningPass?.expiryDate.seconds > moment().unix()) && !followerPage?"1px 0px 10px 4px #ffd700":
                              ((userInfo?.isUserUpgraded && !followerPage) ||
                              (followerInfo?.isUserUpgraded && followerPage))
                                ? "1px 0px 5px 2px #FAE481"
                                :(userInfo?.miningPass?.expiryDate.seconds > moment().unix()) && !followerPage?"0 0 12px 4px #18A2FF"
                                : "1px 0px 5px #6352E8"
                            }`,
                            // background: "#E2BC23",
                            // boxShadow: "0 0 60px 30px #E2BC23",
                            background: `${                              
                              userInfo?.isUserUpgraded && (userInfo?.miningPass?.expiryDate.seconds > moment().unix()) && !followerPage?"linear-gradient(to top, #18A2FF, #FAE481)":
                              ((userInfo?.isUserUpgraded && !followerPage) || 
                              (followerInfo?.isUserUpgraded && followerPage))
                                ? "#FAE481"
                                :(userInfo?.miningPass?.expiryDate.seconds > moment().unix()) && !followerPage?"#18A2FF"
                                : "#6352E8"
                            }`,
                            // boxShadow: "1px 0px 5px #6352E8",
                            // backgroundColor: "#6352E8",
                          }}
                        />
                      </div>
                      <div className="w-100">
                        <HeaderCenter
                          className="d-flex justify-content-between"
                          style={{ width: "16em" }}
                        >
                          {followerPage && followerInfo != "" ? (
                            <>
                              <span
                                className=""
                                style={{
                                  marginLeft: `${
                                    followerInfo?.displayName?.length > 12
                                      ? "50px"
                                      : "70px"
                                  }`,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {followerInfo?.displayName}
                              </span>
                            </>
                          ) : !voteNumber &&
                            userInfo.rewardStatistics?.extraVote == 0 &&
                                contDownValue &&
                                !!new Date(contDownValue).getDate() ? (
                            // @ts-ignore
                            <>
                              {/* @ts-ignore */}
                                    { freeSpinData?.freeSpinAvailable == 0 && megaSpinData?.megaSpinAvailable == 0 ?<Countdown
                                      date={contDownValue}
                                renderer={({
                                  hours,
                                  minutes,
                                  seconds,
                                  completed,
                                }) => {
                                  if (completed) {
                                    getSpinData(userInfo?.uid)
                                  } 
                                  return (
                                    <span
                                      style={{
                                        color: "#6352e8",
                                        fontSize: "10px",
                                        fontWeight: 500,
                                        paddingLeft: "4.5em",
                                      }}
                                    >
                                      {/* {Number(voteRules?.maxVotes)} Votes in{" "} */}   
                                      {"Free spin in"} {" "}                                  
                                      {hours < 1 ? null : `${hours}:`}
                                      {minutes < 10 ? `0${minutes}` : minutes}:
                                      {seconds < 10 ? `0${seconds}` : seconds}
                                      {/* {` left buy mega spin`} */}
                                      {/* for {Number(voteRules?.maxVotes)} votes or buy extra votes now. */}
                                    </span>
                                  );
                                }}
                                    /> : <span
                                        style={{
                                          paddingLeft: "3.3em",
                                    }}
                                      >
                                        {userInfo.voteValue == 0 ? "spin available" : ""}
                                    </span>} 
                            </>
                          ) : (
                            <p className="">
                              <span
                                style={{
                                  color: "#6352E8",
                                  marginLeft: `${voteNumber == 0?"45px":"50px"}`,
                                  fontSize:
                                    window.screen.width <= 340
                                      ? "0.7889em"
                                      : "10px",
                                }}
                              >
                                {/* reward modal 4 */}
                                {
                                  MyPath == "/profile/mine" &&
                                  inOutReward === 2 ? (
                                    <CountUp
                                      useEasing={false}
                                      className={"HeaderText"}
                                      start={
                                        voteNumber == 0 ? 0 : voteNumber || 0
                                      }
                                      end={
                                        (voteNumber || 0) +
                                        (headerExtraVote?.vote || 0)
                                      }
                                      duration={
                                        rewardExtraVote < 10
                                          ? rewardExtraVote
                                          : 10
                                      }
                                      delay={2}
                                      onStart={() => {
                                        // handleExtraVote.play()
                                        // setTimeout(() => {
                                        // 	setTextBlink(true)
                                        // }, 3000);
                                      }}
                                      onEnd={() => {
                                        setTextBlink(false);
                                        handleExtraVote.pause();

                                        setInOutReward((prev: number) => {
                                          // return prev == 2 ? 3 : prev
                                          claimRewardSound.play();
                                          return 3;
                                        });
                                        if (headerExtraVote != 0) {
                                          setShowReward((prev: number) => {
                                            return 3;
                                          });
                                        }

                                        setHeaderExtraVote({
                                          vote: 0,
                                          collect: false,
                                        });

                                        // setHeaderExtraVote((prev: number) => {
                                        // 	if (prev != 0) {
                                        // 		setShowReward((prev: number) => {
                                        // 			// return prev == 2 ? 3 : prev
                                        // 			return 3;
                                        // 		})
                                        // 	}
                                        // 	return prev
                                        // })
                                      }}
                                    />
                                        ) : (
                                            <>                                            
                                              {voteNumber == 0 ? <span style={{ fontSize: "10px" }}>{userInfo.voteValue == 0 ? "spin available" : ""}</span> : Number(voteNumber && voteNumber)}
                                            </>
                                  )
                                  // + (headerExtraVote?.collect ? headerExtraVote?.vote : 0)
                                }{" "}
                                      {voteNumber != 0 && "votes left"}
                              </span>
                            </p>
                          )}
                          {
                            <div style={{ marginRight: "25px" }}>
                              {followerPage && followerInfo != "" ? (
                                <Form.Check.Label
                                  className=""
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "-20px",
                                    border: "1px solid rgb(55, 18, 179)",
                                    borderRadius: "25px",
                                    width: "27px",
                                    height: "27px",
                                    display: "flex",
                                    justifyContent: " center",
                                    alignItems: "center",
                                  }}
                                  bsPrefix="label"
                                  onClick={async () => {
                                    setFollowUnfollow(!followUnfollow);
                                    let folowId: any = undefined;
                                    const ll = leaders.find(
                                      (l) => l.userId === followerInfo?.uid
                                    );
                                    folowId = ll;
                                    if (userInfo && !ll) {
                                      let getChildren: any =
                                        await getmyChildernUser();
                                      let getChild = getChildren.find(
                                        (a: any) =>
                                          a.userId === followerInfo?.uid
                                      );
                                      folowId = getChild;
                                    }
                                    if (user && folowId) {
                                      await follow(
                                        folowId,
                                        user,
                                        !followUnfollow
                                      );
                                    }
                                  }}
                                >
                                  {followUnfollow == true ? (
                                    <Following />
                                  ) : (
                                    <AddFollower />
                                  )}
                                </Form.Check.Label>
                                ) : (
                                    <div
                                      onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                      }}
                                    >
                                    
                                      {freeSpinData?.freeSpinAvailable < 1 && (!silverSpinData?.silverSpinAvailable || silverSpinData?.silverSpinAvailable < 1) && megaSpinData?.megaSpinAvailable < 1 && voteNumber != 0 ?                               
                                        <div
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            marginTop: "7px",
                                            marginLeft: "-20px",

                                          }}
                                          onClick={() => {
                                            navigate(`/wheel`)
                                            handleSoundClick();
                                          }}
                                        >                                          
                                          {userInfo?.voteValue >= spinAccessLimit?<GoldenSpinerHeader />:<>
                                          {Object.keys(freeSpinData).includes('spinnerEnabled') && Object.keys(freeSpinData).includes('votesReceivedFromFirst4Spins') && freeSpinData.totalSpinsUsed < 4 ? <SpinerHeader /> : <GoldenSpinerHeader />}
                                          </>}
                                        </div>
                                        : freeSpinData?.freeSpinAvailable < 1 && voteNumber == 0 ?
                                    // <GoldenSpinerHeader />
                                      <div style={{
                                        width: "30px",
                                        height: "30px",
                                        marginTop: "7px",
                                        marginLeft: "-20px",

                                      }}
                                        onClick={() => {
                                          if (!showMenubar) {
                                            handleSoundClick();
                                            navigate("/wheel");
                                          }
                                        }}
                                      >                                            
                                            {<>
                                            {(megaSpinData?.megaSpinAvailable == 1 || voteNumber == 0) &&                                              
                                              Object.keys(freeSpinData).includes('spinnerEnabled') && Object.keys(freeSpinData).includes('votesReceivedFromFirst4Spins') && freeSpinData.totalSpinsUsed < 4 ? <SpinerHeader /> : <GoldenSpinerHeader /> 
                                            }
                                            </>}
                                            {/* <GoldenSpinerHeader />                                               */}
                                      </div>
                                      :
                                      <div style={{
                                        width: "35px",
                                        height: "35px",
                                        marginTop: "9px",
                                      }}
                                        onClick={() => {
                                          if (!showMenubar) {
                                            handleSoundClick();
                                            // navigate("/votingbooster");                                           
                                            if (megaSpinData?.megaSpinAvailable > 0 || (megaSpinData?.megaSpinAvailable < 1 && (freeSpinData?.freeSpinAvailable > 0 || silverSpinData?.silverSpinAvailable > 0))) {
                                              navigate("/wheel");
                                            }
                                            
                                          }

                                      }}
                                      >                                          
                                            {silverSpinData?.silverSpinAvailable > 0?<SpinerHeader />:
                                            userInfo?.voteValue >= spinAccessLimit?<GoldenSpinerHeader />:<>
                                            {Object.keys(freeSpinData).includes('spinnerEnabled') && Object.keys(freeSpinData).includes('votesReceivedFromFirst4Spins') && freeSpinData.totalSpinsUsed < 4
                                              ?<SpinerHeader />
                                              : megaSpinData?.megaSpinAvailable > 0 ?
                                                <GoldenSpinerHeader /> : freeSpinData?.freeSpinAvailable > 0 && megaSpinData?.megaSpinAvailable < 1 &&<SpinerHeader />}
                                            </>}
                                        </div>}
                                      
                                      {userInfo?.uid && freeSpinData?.freeSpinAvailable < 1 && <Countdown date={contDownValue}
                                        renderer={({ hours, minutes, seconds, completed }) => {
                                          if (completed) {
                                            getSpinData(userInfo?.uid)
                                          }
                                          return (
                                            <>
                                            </>
                                          );

                                        }}
                                      />}
                                    </div>
                              )}
                            </div>
                          }
                          {followerPage && followerInfo != "" ? (
                            ""
                          ) : (
                            <div
                              className="custom-circle-progress"
                              style={{
                                marginRight: "-15px",
                                marginTop:"-7px",
                                cursor: "pointer",
                                borderRadius: "50%",
                                border: "1px solid #160133",
                              }}
                              onClick={() => {
                                navigate("/profile/mine");
                              }}
                            >
                              <HeaderProgressbar
                                percentage={cpmValueShow?.toFixed(3) || 0}
                                remainingReward={remainingReward}
                              />
                            </div>
                          )}
                        </HeaderCenter>
                        {
                          // !(followerPage && followerInfo != "") &&
                          <div
                            className=""
                            style={{
                              width: "170px",
                              marginLeft: "138px",
                              marginTop: "5px",
                              textAlign: "center",
                              fontWeight: "100px",
                            }}
                          >
                            {followerPage && followerInfo != "" ? (
                              ""
                            ) : (
                              <div
                                className="d-flex align-items-center shaped-btn-row"
                                style={{ marginBottom: "4px" }}
                              >
                                <button
                                  className="btn-shaped me-1"
                                  onClick={() => {
                                    navigate("/coins");
                                  }}
                                >
                                  COIN VOTE
                                </button>
                                <button
                                  className="btn-shaped left-side"
                                  onClick={() => {
                                    navigate("/pairs");
                                  }}
                                >
                                  PAIRS VOTE
                                </button>
                              </div>
                            )}
                          {userInfo?.uid && !followerPage &&  <div
                              className="d-flex align-items-center justify-content-center"
                                style={{ margin: "4px 4px 4px 1px",
                                  color:"white"
                                 }}
                            >
                            <img src={coin_bgVET} alt="" width="20px" />  
                            <p>{userInfo?.rewardStatistics?.diamonds} VTE</p>  
                          </div>}
                            <div className="d-none">
                              {followerPage && followerInfo != "" ? (
                                <></>
                              ) : (
                                <span
                                  className={`${
                                    userInfo?.displayName ? "" : "mt-4"
                                  } mb-1`}
                                  style={{
                                    fontSize: "13px",
                                    display: "inline-block",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {`${
                                    userInfo?.displayName
                                      ? userInfo?.displayName
                                      : ""
                                  }`}
                                </span>
                              )}
                              <span style={{ marginLeft: "1px" }}>
                                {!!userInfo?.status?.name && !followerPage && (
                                  <MemberText>
                                    {userInfo?.status?.name}
                                  </MemberText>
                                )}
                              </span>
                            </div>

                            {!!followerInfo && (
                              <div className="d-flex justify-content-center">
                                {!!followerInfo?.status?.name && followerPage && (
                                  <MemberText className="mt-1 ml-1">
                                    {followerInfo?.status?.name}
                                    {userRank > 0 ? (
                                      <>&nbsp; #{userRank}</>
                                    ) : (
                                      ""
                                    )}
                                  </MemberText>
                                )}
                                {!!followerInfo?.bio && followerPage && (
                                  <>
                                    <div className="mx-2 ">
                                      <I
                                        className="bi bi-info-circle"
                                        style={{ paddingTop: "3px" }}
                                        onMouseDown={(e) => {
                                          setTooltipShow(false);
                                        }}
                                        onMouseUp={(e) => {
                                          setTooltipShow(true);
                                        }}
                                        onMouseEnter={() =>
                                          setTooltipShow(true)
                                        }
                                        onMouseLeave={() =>
                                          setTooltipShow(false)
                                        }
                                      ></I>
                                    </div>
                                    {tooltipShow && (
                                      <div
                                        style={{
                                          position: "fixed",
                                        }}
                                      >
                                        <div
                                          className="newtooltip "
                                          style={{
                                            // right: "0%",
                                            width: "300px",
                                            top: "25px",
                                            // marginLeft: `${window.screen.width > 767 ? "2.50%" : ""}`,
                                            // marginTop: `${window.screen.width > 767 ? "10%" : "1%"}`,
                                            // zIndex:3000
                                          }}
                                        >
                                          {/* <p>Your CMP count</p> */}
                                          <p
                                            className="mt-1 lh-base"
                                            style={{
                                              textAlign: "left",
                                            }}
                                          >
                                            {followerInfo?.bio.toUpperCase()}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            )}

                            {followerPage && followerInfo?.isUserUpgraded && (
                              <div
                                style={{
                                  textTransform: "uppercase",
                                  width: "100px",
                                  padding: "2px 10px",
                                  background: "rgb(212, 208, 243)",
                                  color: "rgb(99, 82, 232)",
                                  borderRadius: "10px",
                                  fontSize: "9px",
                                  marginTop: "5px",
                                  marginLeft: "27px",
                                  boxShadow: "#FFD700 1px 0px 5px 2px",
                                }}
                              >
                                VERIFIED MINER
                              </div>
                            )}

                            {/* {(!!userInfo?.status?.name && !followerPage) && <MemberText
																	style={{
																		marginLeft: `${!userInfo?.isUserUpgraded && "-55px"}`
																	}}
																>{userInfo?.status?.name}</MemberText>} */}
                            {!!userInfo?.status?.name &&
                              !followerPage &&
                              !userInfo?.isUserUpgraded && !(userInfo?.miningPass?.expiryDate.seconds > moment().unix()) &&  (
                                <MemberText
                                  style={{
                                    background: "#ff9700",
                                    color: "black",
                                  }}
                                  onClick={() => {
                                    if (!showMenubar) {
                                      handleSoundClick();
                                      navigate("/upgrade");
                                    }
                                  }}
                                >
                                  Earn More
                                </MemberText>
                              )}
                          </div>
                        }
                      </div>
                    </div>
                  )}
                </ForZoom>
                {showReward == 2 &&
                  inOutReward == 2 &&
                  window.screen.width > 767 && <div className="w-100"></div>}
                <Navbar.Brand as={Link} to="/"
                style={{
                  marginTop:`${userInfo?.uid ?"10px":"0px"}`
                }}
                >
                  <img src={BigLogo2} alt="" width="90px" />
                </Navbar.Brand>
              </div>
            </div>
          ) : (
            <div style={{ width: "25%" }}>&nbsp;</div>
          )}

          {/* {for center web size} */}

          <div>
            <Modal
              dialogClassName="modal-35w"
              show={afterVotePopup}
              size="lg"
              onHide={handleClose}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              style={{ opacity: 1, zIndex: 9999 }}
              className="borderColor"
              // animation={false}
              backdrop="static"
              contentClassName={window.screen.width <= 750 ? "w-100" : "w-50"}
            >
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close "
                  aria-label="Close"
                  onClick={() => {
                    setAfterVotePopup(false);
                  }}
                ></button>
              </div>              
              <Modal.Body>
                <div className="d-flex justify-content-center pb-4">
                  <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                    <p className="text-uppercase text-center">
                      {" "}
                      Out of votes? &nbsp;
                    </p>
                    {(voteNumber === 0 && freeSpinData?.freeSpinAvailable && freeSpinData?.spinnerEnabled == true) ?
                      <div
                        style={{
                          width: "35px",
                          height: "28px",
                          // marginTop: "9px",
                        }}
                        onClick={() => {
                          setAfterVotePopup(false);
                          if (!showMenubar) {
                            handleSoundClick();
                            navigate("/wheel");
                          }
                        }}
                      >
                        {/* <SpinerHeader /> */}
                        {userInfo?.voteValue >= spinAccessLimit?<GoldenSpinerHeader />:<SpinerHeader />}
                      </div>
                    : <PlusButton
                      className="d-flex justify-content-center"
                      style={{ padding: "1px", fontSize: "17px" }}
                      onClick={() => {
                        setAfterVotePopup(false);
                        if (!showMenubar) {
                          handleSoundClick();                                         
                            navigate("/votingbooster");                          
                        }
                      }}
                    >
                      <span className={`text-center HeaderText`}>+</span>
                    </PlusButton>}
                  </div>
                </div>
                {/* <hr /> */}

                <div className="text-center d-flex" style={{justifyContent:"center"}}>
                    {/* @ts-ignore */}
                  {/* WAIT{" "}
                  <span className="text-uppercase">
                    {!!new Date(contDownValue).getDate() && (
                      <Countdown
                        date={contDownValue}
                        renderer={({ hours, minutes, seconds, completed }) => {
                          return (
                            <span>
                              {hours < 1 ? null : `${hours}:`}
                              {minutes < 10 ? `0${minutes}` : minutes}:
                              {seconds < 10 ? `0${seconds}` : seconds}
                            </span>
                          );
                        }}
                      />
                    )}
                  </span>{" "}
                  FOR {Number(voteRules?.maxVotes)} VOTES OR
                  {window.screen.width < 465 ? <br /> : <>&nbsp;</>} */}
                  <pre>
                  {voteNumber === 0  && freeSpinData?.freeSpinAvailable > 0 && freeSpinData?.spinnerEnabled == true? <Link
                  
                      style={{ color: "#6352e8", textDecoration: "underline" }}
                    className="text-uppercase"
                    to="/wheel"
                    onClick={() => {
                      handleSoundClick();
                      setAfterVotePopup(false);
                      navigate("/wheel");
                    }}
                  >
                    {/* <Buttons.Primary style={{height:"35px"}}>
                     Free Spin
                    </Buttons.Primary> */}
                    {/* <p style={{height:"35px"}}> */}
                      Free spin available
                    {/* </p> */}
                  </Link>:
                  <Link
                    style={{ color: "#6352e8", textDecoration: "underline" }}
                    className="text-uppercase"
                    to="/votingbooster"
                    onClick={() => {
                      handleSoundClick();
                      setAfterVotePopup(false);
                      navigate("/votingbooster");
                    }}
                  >
                    {/* <Buttons.Primary style={{height:"35px"}}> */}
                    Buy Extra votes
                    {/* </Buttons.Primary> */}
                  </Link>}
                  </pre>
                </div>
              </Modal.Body>
            </Modal>


        <Modal      
          show={miningPassEnd}
          onHide={() => {
            setMiningPassEnd(false);
          }}
          backdrop="static"
          contentClassName=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="d-flex justify-content-end" style={{ zIndex: 100 }}>
            <button
              type="button"
              className="btn-close "
              aria-label="Close"
              onClick={() => {
                setMiningPassEnd(false);
              }}
            ></button>
          </div>
          <Modal.Body className="d-flex  justify-content-center align-items-center">
            <p
              className="py-2"
              style={{ fontSize: "20px", textAlign: "center" }}
            >
              Your Mining Pass has ended! Don’t miss out on the rewards reactivate now and keep earning!
            </p>
          </Modal.Body>
          <div
            className="d-flex justify-content-center pb-3 flex-column align-items-center "
            style={{ zIndex: "101" }}
          >
            <Buttons.Primary
              className="mx-2"
              onClick={async () => {                
                buyMiningPass(
                  upgradeSettingMining[0]?.amount,
                  // upgradeSettingMining[0]?.amount, 
                  upgradeSettingMining[0]?.Days)
              }}
            >
              Buy Mining Pass
            </Buttons.Primary>
          </div>
        </Modal>
          </div>
        </MenuContainer>
      ) : null}
    </>
  );
};

export default Header;
