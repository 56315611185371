import React, { useContext, useEffect, useId, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import Avatars, { AvatarType, defaultAvatar } from "../assets/avatars/Avatars";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserContext from "Contexts/User";
import Progress from "./ProgressBar";
import { CareateObject } from "common/utils/helper";
import { useLocation } from "react-router-dom";
import { motion, AnimatePresence} from "framer-motion";

type banner = {
  id:any
  coin: string;
  CPMRangePercentage: number;
  voteTime: number;
  valueVotingTime: string;
  score: number;
  valueExpirationTime: number;
  multiplier:number;
  voteType: string;
  expiration: number;
  direction: number;
  success: number;
  userId: string;
  animationClass?:string
  userDetails:{
    uid:string,
    isUserUpgraded: boolean,
    avatar: string,
    displayName: string,
    userName: any
  }
  status: {
    name: string;
    minVote: number;
    weight: string;
    share: string;
    index: number;
    givenCPM: string;
    color: string;
  };
  voteId: string;
  timeframe: {
    index: number;
    name: string;
    chosen: boolean;
    seconds: number;
  };
};
const LiveBanner = () => {
  const [voteData, setVoteData] = useState<banner[]>([]);
  const [aboutUser, setAboutUser] = useState<any>([]);
  const [animatedVoteData, setAnimatedVoteData] = useState("");
  const [urlTitle,setUrlTitle] = useState("");
  const [backGround,setBackGround] = useState("");
  const [barPostion,setBarPostion] = useState("");  
  const navigate = useNavigate();
  const { userInfo ,user} = useContext(UserContext);
  const location = useLocation();
  const getTitleFromQuery = () => {
    const params = new URLSearchParams(location.search);
    const title = params.get("title") || "";
    const BG = params.get("BG") || "";
    const bar = params.get("bar") || "";
    
    console.log(title,BG,"getcolorvalue")
    setUrlTitle(title)
    setBackGround(BG)
    setBarPostion(bar)
  };

  useEffect(() => {
    getTitleFromQuery()
    const getCoinData = async () => {     
      const q = query(
        collection(db, "votes"),
        orderBy("voteTime", "desc"),
        limit(3)
      );
      
      onSnapshot(q, async (querySnapshot) => {
        const votes: banner[] = [];
        const AllUserId: string[] = [];
      
        // Collect votes and user IDs
        querySnapshot.forEach((doc) => {
          const voteData = doc.data() as banner;
          // votes.push(voteData);
          votes.push({
            ...voteData,
            id: doc.id, // Assign document ID to vote
          });
          if (!AllUserId.includes(voteData.userId)) {
            AllUserId.push(voteData.userId); // Avoid duplicates
          }
        });
      
        if (AllUserId.length === 0) {
          setVoteData(votes); // No users to fetch, update state with just votes
          return;
        }
      
        // Fetch user details
        const userQuery = query(
          collection(db, "users"),
          where("uid", "in", AllUserId)
        );
      
        const userSnapshot = await getDocs(userQuery);
        const usersMap = new Map<string, any>(); // Map userId -> selected user details
      
        userSnapshot.forEach((userDoc) => {
          const userData = userDoc.data();
          usersMap.set(userData?.uid, {            
            uid: userData?.uid,
            isUserUpgraded: userData?.isUserUpgraded || false,
            avatar: userData?.avatar || "",
            displayName: userData?.displayName || "",
            userName: userData?.userName || "",
          });
        });
      
        // Merge user details into votes
        const mergedVotes = votes.map((vote) => ({
          ...vote,          
          userDetails: usersMap.get(vote.userId) || null, // Attach filtered user details
        }));
      
        setVoteData(mergedVotes);
      });
      
      
    };
    getCoinData();

    return () => {
      getCoinData();
    };
  }, []);

  useEffect(() => {
    const executeFunction = () => {            
        setAnimatedVoteData("Vote-effect")
        const randomDelay = Math.floor(Math.random() * (10000 - 3000 + 1)) + 3000; // Random time between 5-10 sec
        const timeoutId = setTimeout(() => {
          CareateObject(setVoteData,voteData)
          setAnimatedVoteData("")
        }, randomDelay); // Schedule next execution
        return timeoutId;
    };

    const timeoutId = executeFunction(); // Start the loop

    return () => clearTimeout(timeoutId); // Cleanup
}, [aboutUser,voteData]);

  const handleClick = (coins: any) => {
    const symbol = (coins).split("-");
    navigate(`/${symbol.length > 1 ? "pairs" : "coins"}/${coins}`)
    console.log(`/${symbol.length > 1 ? "pairs" : "coins"}/${coins}`, "coiscover");
  };

  const redirectTab = () => {
    if (aboutUser?.uid) {
      window.localStorage.setItem('followerId', aboutUser?.uid)
    }

    // @ts-ignore
    if (aboutUser?.uid === userInfo?.uid) {
      navigate('/Profile/mine')
    } else {
      navigate('/followerProfile/mine')
    }
  }
  return (        
    <>    
    <div className="LiveBannerHeard"
    style={{
      background:`${backGround?backGround:"transparent"}`,
    }}
    >  
        {urlTitle && <div className="GameNameHeading">
            <h3>{urlTitle}</h3>  
        </div>}                
        {barPostion == "0" &&<div>
          <Progress/>
        </div>}
        <div className={`${barPostion==""?"py-2":""}`}>
        <AnimatePresence>
        
          {voteData?.map((data, index) => (
            <motion.div
            key={data.id} // Ensure each item has a unique key
            initial={{ y: -50, opacity: 0, scale: 0.9 }} // Start position
            animate={{ y: 0, opacity: 1, scale: 1 }} // Enter animation
            // exit={{ y: 50, opacity: 0, scale: 0.9 }} // Exit animation
            layout // Enables smooth movement of existing elements
            transition={{ duration: 0.5 }}
            className="px-2"
            style={{
              paddingBottom: `${index === voteData.length - 1 ? "50px" : ""}`
            }}
            onClick={e => {
              if (user) {
                handleClick(data?.coin);
              }
            }}
          >                         
              <div className="voteDetails liveBanner">                
                <div className="voteDetailRow">            
                  <div
                    className="voteCol voteAvtar"
                    style={{
                      boxShadow: `${data?.userDetails?.isUserUpgraded
                        ? "rgb(250, 228, 129) 1px 0px 5px"
                        : "1px 0px 5px #6352E8"
                        }`,
                      outline: `${aboutUser?.isUserUpgraded
                        ? "0.5px solid #D2CC2E"
                        : "0.5px solid #6352E8"
                        }`,
                    }}
                    onClick={e => {
                      e.stopPropagation()
                      if (user) {                  
                        redirectTab()
                      }
                    }}
                  >
                    <Avatars
                      type={(data?.userDetails?.avatar || defaultAvatar) as AvatarType}
                    />
                  </div>
                  <div className="voteCol voteDtl">
                    <div className="voteHead">
                      <h2 className="displayName">
                        {data?.userDetails?.displayName
                          ? `${data?.userDetails?.displayName}`
                          : `${data?.userDetails?.userName}`}
                      </h2>
                      <h4>
                        {data?.status?.name ? (
                          <>
                            {data?.status?.name}
                          </>
                        ) : (
                          ""
                        )}
                      </h4>
                    </div>
      
                    <div className="coinDetailsPara">
                      <div className="clearfix">
                        <div className="coinCompare clearfix">
                          <div className="coinSub pull">
                            <h3 className="coinName">{data?.coin?.split("-")[0]}</h3>
                            <div className="coinLogo">
                              {
                                <Image
                                  src={
                                    process.env.PUBLIC_URL +
                                    `/images/logos/${data?.coin?.includes("-")
                                      ? data?.coin?.split("-")[0]
                                      : data?.coin.toUpperCase()
                                    }.svg`
                                  }
                                  style={{
                                    margin: "0 auto",
                                  }}
                                />
                              }
                            </div>
                          </div>
      
                          {data?.coin?.includes("-") && (
                            <>
                              <div className="sp-line"></div>
      
                              <div className="coinSub">
                                <div className="coinLogo">
                                  {
                                    <Image
                                      src={
                                        process.env.PUBLIC_URL +
                                        `/images/logos/${data?.coin?.includes("-")
                                          ? data?.coin?.split("-")[1]
                                          : data?.coin.toUpperCase()
                                        }.svg`
                                      }
                                      style={{
                                        margin: "0 auto",
                                      }}
                                    />
                                  }
                                </div>
                                <h3 className="coinName">
                                  {data?.coin?.split("-")[1]}
                                </h3>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
      
                      <div className="botContent">
                        <h4>
                          {
                            <>
                              <span>Vote :</span>{" "}
                              {data?.coin?.includes("-")
                                ? data?.coin?.split("-")?.[data?.direction]
                                : data?.direction == 0
                                  ? "BULL"
                                  : "BEAR"}
                            </>
                          }
                        </h4>
                        <h4>
                          {
                            <>
                              <span>Time :</span> {data?.timeframe.name}
                            </>
                          }                          
                        </h4>
                        {window.screen.width > 767 && !data?.coin?.includes("-") && <h4>
                            {
                              <>
                                <span>Voting Power :</span> {data?.multiplier}
                              </>
                            }
                          </h4> } 
                      </div>
                    </div>
                    {(window.screen.width < 767 || data?.coin?.includes("-")) &&<div className="text-center mt-3 VotePower">
                        <h4>
                            {
                              <>
                                <span>Voting Power :</span> {data?.multiplier}
                              </>
                            }
                          </h4>
                        </div>}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
          </AnimatePresence>
        </div>   
        {barPostion == "1" &&<div>
          <Progress/>
        </div>}   
    </div>
    </>
  );
};

export default LiveBanner;