import { VoteResultProps } from 'common/models/Vote'
import React, { useEffect, useState } from 'react'
import ExtraVTE from './ExtraVte';
import ExtraSpin from './ExtraSpin';
import ExtraCard from './ExtraCard';
import { Modal } from 'react-bootstrap';
import "./GoldenVoteText.css"

function AllGoldenVotePopup({
    popUpOpen,
    vote,
    type,
    setLessTimeVoteDetails,
    setShowComplete100CMP,
    currentCMP,
  }
    : {
      popUpOpen?: any,
      vote: any,
      type?: string,
      setLessTimeVoteDetails: React.Dispatch<React.SetStateAction<VoteResultProps | undefined>>,
      setShowComplete100CMP: React.Dispatch<React.SetStateAction<boolean>>,
      currentCMP: number
    }) {

    const [openPopup, setOpenPopup] = useState<any>("none");
    const [cardModalShow, setCardModalShow] = useState<boolean>(false);
    const [changeBG, setChangeBG] = React.useState(0);
    const [rewardTimer, setRewardTimer] = React.useState(  
    {
      firstRewardCardType: "RARE",
      firstRewardCardId: "hu1o5R3KVpP2SWiGx5RY",
      firstRewardCard: "Astro Rhino",
      firstRewardCardCollection: "Space",
      firstRewardCardSerialNo: "SPSPRA1290",
      firstRewardCardImageUrl: "https://storage.googleapis.com/coin-parliament-staging.appspot.com/Space/Astro%20Rhino.png?GoogleAccessId=firebase-adminsdk-c90eh%40coin-parliament-staging.iam.gserviceaccount.com&Expires=16447017600&Signature=M%2F7CYnFPsE8eKn43kbH3vOgjpy%2Blduf0WX%2BFnB70aIlZdY6mh1NGGW1JhF9v%2Fh89KCYSznp3raOaDcHbvlzxN%2FTRPjBTPsKcQY%2BSD76Dqap4L3B4TlyKiMGuWQ7taSLUlsnvq7euXfp50sVc%2FUKFfl1jFFD3KZXzB4ZYK4CZ1nA%2BFz3hE4aWqS1OvE4ymaB146UNbCxzkxhZHbKeWIG8y%2FCNtABOTf8%2F%2BuWTgBfjfuMdPhhWQnHZYFnSKhfPbWM2Ft9K813QWKZqpMqF8PQzwFfVStcuMLGiIB7YZxXDhc2WVQPZGLAH%2FzN353OKJUJr5sa4IoxC2vgDGFGG%2FdqfAQ%3D%3D",
      firstRewardCardVideoUrl: "https://storage.googleapis.com/coin-parliament-staging.appspot.com/Space/Astro%20Rhino.gif?GoogleAccessId=firebase-adminsdk-c90eh%40coin-parliament-staging.iam.gserviceaccount.com&Expires=16447017600&Signature=C6y%2FqSV3mbfzL4FO986mMJcvQ44eimfaVEQ1h9uJ63yxLJ0t34%2B2AVzbVhU4fR6uWj7J3kMsRgROcjxbLM9c6wLQBQjrh9caJgE2K%2BlhjD%2BHl9WCiSQdhEbqtDqMe1u5Zc6If425vSwDZlFQXnObunxSd4QkC6Y9Z1ROgipih9mW3VLOKVzdKNaau4tmHI%2B7%2B3jldEQIOGNz55TRpmbeH8McUcPV2zTFeARMyEogj4w8V%2FeqGJzQOd%2FTGHNv5sP8PDcrGbSH2wxe%2BZGUtGx%2F2QQPe%2BWnl4vKyxQKK475C99B0nJsbs5GkiM%2F0%2FttbKbiQfGvz9ueyyEkxP7Lw2ajDQ%3D%3D",
      secondRewardExtraVotes: 11,
      thirdRewardDiamonds: 7
   }  
);

    useEffect(() => {
      // setOpenPopup("CARD") 
      setOpenPopup(vote?.goldenVoteReward?.type) 
        if(vote?.goldenVoteReward?.type=="CARD"){
          if(vote?.goldenVoteReward?.cardDetails?.firstRewardCardType){
            setRewardTimer(vote?.goldenVoteReward?.cardDetails)
          }
          setTimeout(() => {            
            setCardModalShow(true)
          }, 200);
        }      
    },[])


    console.log(vote,"votevotevote11221")


  return (
    <div>
      {(openPopup=="EXTRAVOTES" || openPopup=="VTE" || openPopup=="MININGPASS" || openPopup=="10SEC_VOTETIME")&& 
      <>
        <ExtraVTE 
        type={openPopup}
        GoldenVote={vote}
        setLessTimeVoteDetails={setLessTimeVoteDetails}
        setOpenPopup={setOpenPopup}
        />
      </>
      }

      {(openPopup=="MEGASPIN" || openPopup=="SILVERSPIN")&& 
      <>
        <ExtraSpin
        type={openPopup}
        GoldenVote={vote}
        setLessTimeVoteDetails={setLessTimeVoteDetails}
        setOpenPopup={setOpenPopup}
        />
      </>
      }
      
      {openPopup=="CARD"&& 
      <>

      <div>
        {/* reward modal 5 */}
        <Modal
          className=""
          show={
            cardModalShow
          } onHide={()=>{setCardModalShow(false)}}
          // fullscreen="sm-down"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ backgroundColor: "rgba(0,0,0,0.9)", zIndex: "2200" }}
          animation={false}
          // @ts-ignore
          contentClassName={window.screen.width > 767 ? `card-content modulebackground ForBigNft ${changeBG < 82 ? "sameBG" : `${rewardTimer?.firstRewardCardType?.toLowerCase()}BG`}` : `card-contentMob modulebackground ForBigNft ${changeBG < 82 ? "sameBG" : `${rewardTimer?.firstRewardCardType?.toLowerCase()}BG`}`}
        >
          <div className='d-flex justify-content-center GoldenVoteText'>
            <p>Golden Vote Reward</p>
          </div>
          <div className="d-flex justify-content-end">           
          </div>
          <Modal.Body
            style={{
              padding: "0px"
            }}
          >            
            {/* @ts-ignore */}
            {/* <NFTCard openpopup={openpopup} setRewardTimer={setRewardTimer} setCountShow={setCountShow} handleShareModleShow={handleShareModleShow} handleCardClose={handleCardClose} cardType={rewardTimer?.data?.firstRewardCardType} rewardTimer={rewardTimer} setBefornotShow={setBefornotShow} befornotShow={befornotShow} setCardsDetails={setCardsDetails} setAddPaxWalletPop={setAddPaxWalletPop} setChangeBG={setChangeBG} /> */}
            <ExtraCard type={openPopup} GoldenVote={vote} setLessTimeVoteDetails={setLessTimeVoteDetails} setOpenPopup={setOpenPopup} setChangeBG={setChangeBG}rewardTimer={rewardTimer} cardType={rewardTimer?.firstRewardCardType}/>
          </Modal.Body>
        </Modal>
      </div>
        
      </>
      }
    </div>
  )
}

export default AllGoldenVotePopup
