import React, { useContext, useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import Avatars, { AvatarType, defaultAvatar } from "../assets/avatars/Avatars";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserContext from "Contexts/User";
import AppContext from "Contexts/AppContext";
import moment from "moment";

type banner = {
  coin: string;
  CPMRangePercentage: number;
  voteTime: number;
  valueVotingTime: string;
  score: number;
  displayName: string;
  valueExpirationTime: number;
  multiplier:number
  voteType: string;
  expiration: number;
  direction: number;
  success: number;
  userId: string;
  status: {
    name: string;
    minVote: number;
    weight: string;
    share: string;
    index: number;
    givenCPM: string;
    color: string;
  };
  voteId: string;
  timeframe: {
    index: number;
    name: string;
    chosen: boolean;
    seconds: number;
  };
};
const LatestVoteBanner = () => {
  const [data, setData] = useState<banner>();
  const [aboutUser, setAboutUser] = useState<any>();
  const [voteUserId, setVoteUserId] = useState<any>("");
  const navigate = useNavigate();
  const { userInfo, user } = useContext(UserContext);
  const { setLogin, setLoginRedirectMessage } = useContext(AppContext);

  useEffect(() => {
    const getCoinData = async () => {
      const q = query(
        collection(db, "votes"),
        orderBy("voteTime", "desc"),
        limit(1)
      );
      onSnapshot(q, async (querySnapshot) => {
        let votes = {};
        querySnapshot.forEach((doc) => {
          votes = doc.data();
        });        
        //@ts-ignore
        const userId = votes?.userId;
        setVoteUserId(userId)
        setData(votes as banner);        
      });
    };
    getCoinData();

    return () => {
      getCoinData();
    };
  }, []);


  useEffect(() => {
    const getUserData = async () => {
      console.log(voteUserId,"voteUserId")
      if (voteUserId) {    
        try {
          const userQuery = query(collection(db, "users"), where("uid", "==", voteUserId));
          const userSnapshot = await getDocs(userQuery);
          userSnapshot.forEach((userDoc) => {
            const userData = userDoc.data();
            console.log(userData, "userData");
            setAboutUser(userData);
          });
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      }
    }
    getUserData()
    return () => {
      // second
      getUserData()
    }
  }, [voteUserId])

  

  const handleClick = (coins: any) => {    
    const symbol = (coins).split("-");
    navigate(`/${symbol.length > 1 ? "pairs" : "coins"}/${coins}`)
    console.log(`/${symbol.length > 1 ? "pairs" : "coins"}/${coins}`, "coiscover");
  };

  const avatarClick = (userId:any) => {
    if(!userId) return
    if (aboutUser?.uid) {
      window.localStorage.setItem('followerId', aboutUser?.uid)
    }
    // @ts-ignore
    if (aboutUser?.uid === userInfo?.uid) {
      navigate('/Profile/mine')
    } else {
      navigate('/followerProfile/mine')
    }
  }  
  return (
    <>
      {aboutUser &&
        <div className="px-2">
          <div className="voteDetails"
            onClick={e => {
              if (user) {                
                handleClick(data?.coin)
              }else{
                setLoginRedirectMessage("check these details");
                setLogin(true);
              }
            }}>
            <div className="voteDetailRow">
              <div
                className="voteCol voteAvtar"
                style={{
                  boxShadow: `${aboutUser?.isUserUpgraded
                    ? "rgb(250, 228, 129) 1px 0px 5px"
                    : "1px 0px 5px #6352E8"
                    }`,
                  outline: `${aboutUser?.isUserUpgraded
                    ? "0.5px solid #D2CC2E"
                    : "0.5px solid #6352E8"
                    }`,
                }}
                onClick={e => {
                  e.stopPropagation()
                  if (user) {
                    avatarClick(aboutUser?.uid)
                  }else{
                    setLoginRedirectMessage("check these details");
                    setLogin(true);
                  }
                }}
              >
                <Avatars
                  type={(aboutUser?.avatar || defaultAvatar) as AvatarType}
                />
              </div>
              <div className="voteCol voteDtl">
                <div className="voteHead">
                  <h2 className="displayName">
                    {aboutUser?.displayName
                      ? `${aboutUser?.displayName}`
                      : `${aboutUser?.userName}`}
                  </h2>
                  <h4>
                    {data?.status?.name ? (
                      <>
                        {data?.status?.name}
                      </>
                    ) : (
                      ""
                    )}
                  </h4>
                </div>

                <div className="coinDetailsPara">
                  <div className="clearfix">
                    <div className="coinCompare clearfix">
                      <div className="coinSub pull">
                        <h3 className="coinName">{data?.coin?.split("-")[0] == "MATIC" ?"POL":data?.coin?.split("-")[0]}</h3>
                        <div className="coinLogo">
                          {
                            <Image
                              src={
                                process.env.PUBLIC_URL +
                                `/images/logos/${data?.coin?.includes("-")
                                  ? data?.coin?.split("-")[0]
                                  : data?.coin.toUpperCase()
                                }.svg`
                              }
                              style={{
                                margin: "0 auto",
                              }}
                            />
                          }
                        </div>
                      </div>

                      {data?.coin?.includes("-") && (
                        <>
                          <div className="sp-line"></div>

                          <div className="coinSub">
                            <div className="coinLogo">
                              {
                                <Image
                                  src={
                                    process.env.PUBLIC_URL +
                                    `/images/logos/${data?.coin?.includes("-")
                                      ? data?.coin?.split("-")[1]
                                      : data?.coin.toUpperCase()
                                    }.svg`
                                  }
                                  style={{
                                    margin: "0 auto",
                                  }}
                                />
                              }
                            </div>
                            <h3 className="coinName">
                              {data?.coin?.split("-")[1]== "MATIC" ?"POL":data?.coin?.split("-")[1]}
                            </h3>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                <div className="botContent">
                    <h4>
                      {
                        <>
                          <span>Vote :</span>{" "}
                          {data?.coin?.includes("-")
                            ? data?.coin?.split("-")?.[data?.direction]
                            : data?.direction == 0
                              ? "BULL"
                              : "BEAR"}
                        </>
                      }
                    </h4>
                    <h4>
                      {
                        <>
                          <span>Time :</span> {data?.timeframe.name}
                        </>
                      }
                    </h4>                    
                    {window.screen.width > 767 && !data?.coin?.includes("-") && <h4>
                      {
                        <>
                          <span>Voting Power :</span> {data?.multiplier}
                        </>
                      }
                    </h4> }                   
                  </div>                                  
                </div>     
                {(window.screen.width < 767 || data?.coin?.includes("-")) &&<div className="text-center mt-3 VotePower">
                  <h4>
                      {
                        <>
                          <span>Voting Power :</span> {data?.multiplier}
                        </>
                      }
                    </h4>
                  </div>   }        
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default LatestVoteBanner;