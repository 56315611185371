import { Buttons } from 'Components/Atoms/Button/Button'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import CountUp from 'react-countup'
import RewardSpin from 'Components/Profile/RewardSpinWheel/RewardSpin'
import styled from 'styled-components'
import AppContext from 'Contexts/AppContext'
import { VoteContext, VoteDispatchContext } from 'Contexts/VoteProvider'
import { CompletedVotesDispatchContext } from 'Contexts/CompletedVotesProvider'
import confetti from "../../assets/animation/confetti.json";
import UserContext from 'Contexts/User'
import useSound from 'use-sound'
// @ts-ignore
import claimSound from '../../assets/sounds/claimReward.m4a';
import FreeRewardSpin from './FreeRewardSpin'
import MegaRewardSpin from './MegaRewardSpin'
import JackPotImag from '../../assets/images/jackpot.png'
import Lottie from 'lottie-web'
const Popuphead = styled.p`
  font-size: 25px;
  font-weight: 600;
  background: linear-gradient(180deg, #fff8a6 29.44%, #ffc00b 73.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // text-shadow: 0px 1px 3px #FFCB35;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
`;


interface ExtraFreeSpinProps {
  type?:any,
  GoldenVote?:any,
  setLessTimeVoteDetails: React.Dispatch<React.SetStateAction<any>>,
  setOpenPopup: React.Dispatch<React.SetStateAction<any>>;
}
  const ExtraSpin : React.FC<ExtraFreeSpinProps> = ({type, GoldenVote,setLessTimeVoteDetails,setOpenPopup})=> {
  const [showGetVTE, setShowGetVTE] = React.useState(false);
    const [showCoinMove, setShowCoinMove] = React.useState(true);
    const [afterLoad, setafterLoad ] = React.useState<any>(false);
    const {setAnimateBox,setAfterVotePopup, } = useContext(AppContext);
    const { headerCpmStart,setHeaderCpmStart, } = useContext(AppContext);    
    const voteDetails = useContext(VoteContext);
    const setVoteDetails = useContext(VoteDispatchContext);
    const setCompletedVotes = useContext(CompletedVotesDispatchContext);
    const { userInfo } = useContext(UserContext);
    const [claimRewardSoundplay, { pause: claimRewardSoundpause }] = useSound(claimSound);

    const [showButtonMove, setShowButtonMove] = useState<boolean>(true);
    const [FreeSpinModule, setFreeSpinModule] = useState<boolean>(false);
    const [fresVoteValue, setFresVoteValue] = useState<number>(0);
    const [jackPotReward, setJackPotReward] = useState("");
    // const handleClose = () => setFreeSpinModule(false);
  const handleShow = () => setFreeSpinModule(true); 
  
  console.log(GoldenVote,"voteDetailsvoteDetails11221122")
  const removeVote = () => {
    let temp = {};
    setVoteDetails((prev) => {
      // console.log(Object.keys(prev?.activeVotes),"Object.keys(prev?.activeVotes)")
      Object.keys(prev?.activeVotes).map((key: string) => {
        console.log(key,"Object.keys(prev?.activeVotes)")
        if (/* prev?.activeVotes[key].expiration > new Date().getTime() &&  */GoldenVote?.voteId !== voteDetails?.activeVotes[key].voteId) {
          temp = { ...temp, [`${voteDetails?.activeVotes[key].coin}_${voteDetails?.activeVotes[key]?.timeframe?.seconds}`]: voteDetails?.activeVotes[key] }
        }
      });
      console.log(GoldenVote, prev?.activeVotes.voteId,"temptemptemp")
      return {
        ...prev,
        lessTimeVote: undefined,
        activeVotes: temp,
        openResultModal: false,        
      };
    });
    if (Object.keys(temp)?.length <= 0 && (Number(userInfo?.voteValue) + Number(userInfo?.rewardStatistics?.extraVote)) <= 0) {
      setAfterVotePopup(true);
    }
    setCompletedVotes(prev => prev.filter(value => value.voteId != GoldenVote.voteId));
    setLessTimeVoteDetails(undefined);      
  }

  const handleClose = () => {
    removeVote();    
    setHeaderCpmStart(true);            
    setAnimateBox(true);
    setOpenPopup("none")
    setTimeout(() => {
      setAnimateBox(false);        
  }, 10000 )
  };

  useEffect(() =>{
    handleShow()
  },[type])

  const [step, setStep] = useState(0);
  useEffect(() => {
    const timers:any = [];
    if(jackPotReward){
      timers.push(setTimeout(() => setStep(1), 1000));  // Show first
      timers.push(setTimeout(() => setStep(2), 3000));  // Hide first
      timers.push(setTimeout(() => setStep(3), 4500));  // Show second
      timers.push(setTimeout(() => setStep(4), 5500));  // Hide second
      timers.push(setTimeout(() => setStep(5), 6500));  // Show third
      timers.push(setTimeout(() => setStep(6), 7500));  // Hide third
      timers.push(setTimeout(() => setStep(7), 8600));  // Show all
    }

    if(type!="SILVERSPIN" && jackPotReward !="" ){
      const Animation = Lottie.loadAnimation({
        // @ts-ignore      
        container: document.querySelector("#card-animation"),
        animationData: confetti,
        renderer: "html", // "canvas", "html"
        loop: true, // boolean
        autoplay: true, // boolean                    
      });
  
      // setTimeout(function () {
      //   Animation.pause();        
      // }, 8000);
    }

    return () => timers.forEach(clearTimeout);
  }, [jackPotReward]);  


  return (
    <div>
          {/* reward modal 3 */}
          <Modal
            show={
                FreeSpinModule
            } onHide={handleClose}
            backdrop="static"            
            contentClassName={"modulebackground ForBigDiv"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backgroundColor: "rgba(0,0,0,0.9)", zIndex: "2200" }}
            animation={false}
          >
            <div className='d-flex justify-content-center GoldenVoteText'
            
            >
            {type!="SILVERSPIN" && jackPotReward !="" ?
            <div className='' style={{marginTop:"-50px"}}>
            <img src={JackPotImag} alt="" width="230px"/>
            </div>
            :<p>Golden Vote Reward</p>}
          </div>
          {type!="SILVERSPIN" && jackPotReward !="" &&<div
                id={type!="SILVERSPIN" && jackPotReward !="" ?'card-animation':""}
                style={{
                  height: "400px",
                  width: "400px",
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  left: "0px",
                  margin: "0px auto",
                }}
              />}
            <Modal.Body className="d-flex  flex-column  justify-content-between align-items-center"
            
              style={{
                width: `${window.screen.width > 767 ? "500px" : "100%"}`,
                height: "400px"
              }}
            >
              {!showButtonMove ?<>              
              <Popuphead>Congrats!</Popuphead>
              {/* @ts-ignore */}
              <div className=''>
                  {
                  type=="SILVERSPIN"?
                  <p style={{ fontSize: "24px", color: "white", fontWeight: "600" }}>You've won {
                  <CountUp
                    end = {fresVoteValue}
                    duration = { 2.75}
                  />
                  } {fresVoteValue ==2 ?"Free Spin":"votes"}</p>
                  
                  :

                  <div className='text-center'>                  
                  {jackPotReward !="" 
                  
                  ?                                   
                    <div className="wrapper">
                    <p 
                      className={`rewadTextfade ${step === 1 || step === 7 ? 'visible' : 'hidden'}`}
                      style={{ fontSize: "24px", color: "white", fontWeight: "600" }}>You've won {
                      <CountUp
                        end = {fresVoteValue}
                        duration = { 2.75}
                      />
                      } {fresVoteValue ==2 ?"Free Spin":"votes"}</p>
                    <p  
                      className={`rewadTextfade ${step === 3 || step === 7 ? 'visible' : 'hidden'}`}
                      style={{ fontSize: "20px", color: "white", fontWeight: "600" }}>You've won 50 VTE </p>
                      <p  
                      className={`rewadTextfade ${step === 5 || step === 7 ? 'visible' : 'hidden'}`}
                      style={{ fontSize: "20px", color: "white", fontWeight: "600" }}>You've Mining pass is Active</p>
                      
                    </div>
                  :
                  <p                   
                  style={{ fontSize: "24px", color: "white", fontWeight: "600" }}>You've won {
                  <CountUp
                    end = {fresVoteValue}
                    duration = { 2.75}
                  />
                  } votes</p>
                  }
                  </div>
                  }
              </div>              
              {/* <Modal.Footer> */}
              <div className="d-flex justify-content-center ">
                  <Buttons.Primary className="mx-2"                                        
                    onClick={() => {
                      handleClose()
                    }}>{type!="SILVERSPIN" && jackPotReward !=""  ? "COLLECT YOUR REWARDS":"COLLECT YOUR VOTES"}</Buttons.Primary>
              </div>
              </> :
                <div className="w-100 d-flex justify-content-center align-items-center m-auto"                
                >               
                {type=="MEGASPIN"?
                  <MegaRewardSpin
                    setFresVoteValue={setFresVoteValue}
                    setShowButtonMove={setShowButtonMove}
                    rewardTimer={GoldenVote}
                    claimRewardSoundplay={claimRewardSoundplay}
                    setJackPotReward={setJackPotReward}
                    />
                  :<FreeRewardSpin
                    setFresVoteValue={setFresVoteValue}
                    setShowButtonMove={setShowButtonMove}
                    rewardTimer={GoldenVote}
                    claimRewardSoundplay={claimRewardSoundplay}                    
                  />}
              </div>}
            </Modal.Body>            
          </Modal>
        </div>
  )
}

export default ExtraSpin
