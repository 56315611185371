import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../Contexts/User";
import Notification from "./Notification";
import {useTranslation} from "../../common/models/Dictionary";
import styled from "styled-components";
import {Container} from "react-bootstrap";
import { NotificationWait } from "../../common/utils/SoundClick";
import {getAllNotifications } from "common/utils/helper";
import AppContext from "Contexts/AppContext";
import axios from "axios";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import WaitForResponse from "./CardTrade/WaitForResponse";

const P = styled.p`
  text-align: center;
  padding: 10px;

  &:first-letter {
    text-transform: capitalize;
  }
`
const Notifications = () => {
  const { notifications, setNotifications, userInfo } = useContext(UserContext)
  const {setNotificationCount,notificationCount} = useContext(AppContext)
  
  // const [dataLodaing, setDataLodaing] = useState(false)
  
  const [notificationsList, setNotificationsList] = useState<any>(notifications)
  const [limitValue, setLimitValue] = useState<number>(10)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [categoryFilter, setCategoryFilter] = useState<string>("");
  
  // useEffect(() => {
    
  //   if (notifications.length) {
  //   NotificationWait()
  // }
 
  // }, [notifications])
  const updateNotificationSeenForCardTrade = httpsCallable(functions, "updateNotificationSeenForCardTrade")  
  const listNotifications = httpsCallable(functions, "listNotifications")  
  const getMoreDataLoda = (limitNumber:number) => {    
    if (userInfo?.uid) {      
      getAllNotifications(userInfo?.uid, setNotifications, limitNumber)
    }
  }
  const NOTIFICATION_TYPES = {
    // MINING_PASS: "MINING_PASS",
    // GOLDEN_VOTE: "GOLDEN_VOTE",
    // GOLDEN_VOTE_AVAILABLE: "GOLDEN_VOTE_AVAILABLE",
    // CARD_TRADE: "CARD_TRADE",
    // FREE_SPIN: "FREE_SPIN",
    // GENERAL: "GENERAL",
    // PARENT_REFERRAL: "PARENT_REFERRAL",
    // BOOST_ACTIVATED: "BOOST_ACTIVATED",
    // BOOST_DEACTIVATED: "BOOST_DEACTIVATED",
    // VOTE_ACTIVITY: "VOTE_ACTIVITY",
    // VOTE_REMINDER: "VOTE_REMINDER",
    // CMP_REWARD: "CMP_REWARD",
    // CLAIM_REWARD: "CLAIM_REWARD",
    // TITLE_UPGRADE: "TITLE_UPGRADE",
    // TITLE_DOWNGRADE: "TITLE_DOWNGRADE",
    // PAX_TRANSACTIONS: "PAX_TRANSACTIONS",
    // CMP_TRANSACTIONS: "CMP_TRANSACTIONS",
    // REFERRAL_EARNINGS: "REFERRAL_EARNINGS",
    // COIN_PRICE_FLUCTUATION: "COIN_PRICE_FLUCTUATION",

    MINING_PASS: "MINING_PASS",
    GOLDEN_VOTE: "GOLDEN_VOTE",
    GOLDEN_VOTE_AVAILABLE: "GOLDEN_VOTE_AVAILABLE",
    CARD_TRADE: "CARDTRADE",
    FREE_SPIN: "FREE_SPIN",
    PARENT_REFERRAL: "PARENT_REFERRAL",
    BOOST_DEACTIVATED: "BOOST_DEACTIVATED",
    VOTE_ACTIVITY: "VOTE_ACTIVITY",
    VOTE_REMINDER: "VOTE_REMINDER",
    CMP_REWARD: "CMP_REWARD",
    CLAIM_REWARD: "CLAIM_REWARD",
    TITLE_UPGRADE: "TITLE_UPGRADE",
    TITLE_DOWNGRADE: "TITLE_DOWNGRADE",
    PAX_TRANSACTIONS: "PAX_TRANSACTIONS",
    CMP_TRANSACTIONS: "CMP_TRANSACTIONS",
    REFERRAL_EARNINGS: "REFERRAL_EARNINGS",
    COIN_PRICE_FLUCTUATION: "COIN_PRICE_FLUCTUATION",
    IN_THE_ZONE_BOOST_ACTIVATED: "IN_THE_ZONE_BOOST_ACTIVATED",
    ON_FIRE_BOOST_ACTIVATED: "ON_FIRE_BOOST_ACTIVATED"
  };

  useEffect(() => {
    const fetchNotifications = async () => {        
        const data={  
          userId:userInfo?.uid,     
            notificationType:categoryFilter,
            page:1,        
            limit:limitValue,
        }
        setIsLoading(true)
        try {
            const response = await listNotifications(data).then((res)=>{
              // @ts-ignore
              setNotificationsList(res?.data?.data?.notifications)
              setIsLoading(false)
              // @ts-ignore
              console.log(res?.data?.data?.notifications,notifications,"notificationsnotifications")
            })
            
            // setNotifications(response?.data);
        } catch (err) {
            // setError(err);
            console.log(err)
            setIsLoading(false)
        }
    };
    if(userInfo?.uid){
      fetchNotifications();
    }
}, [limitValue,userInfo?.uid,categoryFilter]);


  useEffect(() => {
    if (userInfo?.uid) {      
      // NotificationSeen() 
      getMoreDataLoda(limitValue)   
    }
  }, [userInfo?.uid])
  
  const NotificationSeen = () => {
    setIsLoading(true)
    const data = {
      userId: userInfo?.uid,      
    }    
    updateNotificationSeenForCardTrade(data).then((result) => {
      // setNotificationCount(0)
      console.log(result)
      setIsLoading(false)
    }).catch((err) => {
      console.log(err)
    });
  }

  const translate = useTranslation()
  return (
    <Container className="py-4">
            {isLoading && <>
              <WaitForResponse />
            </>}            
      {notifications.length > 0&& <div className="d-flex justify-content-center"      >
        <div className="d-flex justify-content-center align-items-center"
        style={{
          width:`${window.screen.width >767 ?"13%":"30%"}`,
          opacity:`${notificationCount >0 ?"1":"0.7"}`,
          cursor:`${notificationCount >0 ?"pointer":"not-allowed"}`,
          color:"white"
        }}
        onClick={() => {
          if(notificationCount > 0 && userInfo?.uid){
            NotificationSeen()
          }
        }}
        >
        <FontAwesomeIcon icon={faCheckDouble} style={{color: "#ffffff", marginRight:"3px"}} /> <p>Mark All Read</p> 
        </div>
      </div>}
      <div className="text-center my-3">
        <select value={categoryFilter} onChange={(e) => {
            setCategoryFilter(e.target.value)
          }}
          style={{
          padding:"8px",
          borderRadius:"5px"
          }}
          >
          <option value="">All Categories</option>
          {Object.entries(NOTIFICATION_TYPES).map(([key, value]) => (
            <option key={key} value={value}>
              {value.replace(/_/g, ' ')}
            </option>
          ))}
        </select>
      </div>
      {!notificationsList?.length && <P className="text-white">{translate("you have no notifications")}</P>}
      {
        notificationsList?.sort((a:any, b:any) => b.time - a.time)
        .map((notification:any, index:number) => {
          console.log(notification?.time,"notification?.time")
          const date = notification?.time && notification?.time?._seconds ?new Date(notification?.time?._seconds * 1000): notification?.time.toDate() || undefined;
          // const dataTime = new Date(date).toString()
          return <Notification date={date} title={notification?.message?.title} body={notification?.message?.body} key={index} notificationData={notification} limitValue={limitValue}/>;
        })}
      {limitValue == notificationsList?.length && <>
        <div
        className="text-center"
        >
          <p
            
            style={{
              color: "#6352e8",              
              cursor: "pointer",
              fontSize: "15px",
              padding: "10px",
            }}
            // className={`${dataLodaing ?"loading" :""}`}
            onClick={() => {
              // getMoreDataLoda(limitValue + 10)
              setLimitValue(limitValue + 10)
              // setDataLodaing(true)
            }}
          >
            {/* {!dataLodaing ? "View more" : "Wait for it..."} */}
            View more
          </p>
        </div>
      </>}
    </Container>

  );
};

export default Notifications;
