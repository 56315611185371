import React from "react";
import { TimeFrame } from "../common/models/Vote";
import { UserTypeProps } from "../common/models/UserType";
import { ProfileTabs } from "../Pages/Profile";

export type VoteRules = {
  maxVotes: number;
  givenCPM: number;
  CPMReturnSuccess: number;
  CPMReturnFailure: number;
  timeLimit: number;
};

export type CPMSettings = {
  signupReferral: number;
  pctReferralActivity: number;
  orderBookWeight: number;
};

export type AppStats = {
  totalVotes: number;
}

export type PaxData = {
  blocksGiven: number;
};

export type AppContextProps = {
  silverSpinData?:any,
  setSilverSpinData?:any,
  lastCmpValue?:any
  setOtherModalOpen?:any;
  goldenValue?:any, 
  setGoldenValue?:any,
  addVoteMessage?:string, 
  setAddVoteMessage?:any,
  spinAccessLimit?:any, 
  setSpinAccessLimit?:any,
  currentIndex?:any, 
  setCurrentIndex?:any,
  boosterValueArray?:any, 
  setBoosterValueArray?:any,
  boosterButtonBlink?:any,
  setBoosterButtonBlink?:any,
  headerCpmStart?:any,
  setHeaderCpmStart?:any,
  claimRewardStart?:any,
  setClaimRewardStart?:any,
  boosterVote:number,
  setBoosterVote?:any,
  oneVoteOnly?:any,
  setOneVoteOnly?:any,
  getSpinData?:any,
  megaSpinData?:any,
  setMegaSpinData?:any,
  freeSpinData?:any,
  setFreeSpinData?:any,
  contDownValue?:any,
  setContDownValue?:any,
  seletcardHolderType?:any,
  setSeletcardHolderType?:any,
  prevCMP?:any,
  lastCpmMini?:any,
  latestCMP?:any,
  extraVoteModule?: any,
  setExtraVoteModule?: any,
  userChildern?:any,
  setUserChildern?: any,
  userLeadersData?:any,
  setUserLeadersData?:any,
  userSubscribersData?:any,
  setUserSubscribersData?:any,
  loader?:boolean,
  setLoader?:any,
  paymentCoinList?:any,
  setPaymentCoinList?:any,
  addPaxWalletPop?:any,
  setAddPaxWalletPop?:any,
  walletTab?:any,
  setWalletTab?: any,
  historyTab?: any,
  setHistoryTab?: any,
  isVirtualCall?:any, 
  setIsVirtualCall?:any,
  avatarImage?: any,
  setAvatarImage?: any,
  selectBioEdit?: any,
  setSelectBioEdit?: any,
  upgraeShowOpen?: any,
  setUpgraeShowOpen?: any,
  withLoginV2e?:any,
  setWithLoginV2e?:any,
  transactionId?: any,
  setTransactionId?: any,
  setBackgrounHide?: any,
  backgrounHide?: any,
  voteNumberEnd: any,
  setShowMenuBar: any,
  showMenubar: any,
  firstTimeAvatarSlection: any,
  setFirstTimeAvatarSelection: React.Dispatch<React.SetStateAction<boolean>>,
  setvoteNumberEnd?: any,
  albumOpen?: any,
  setAlbumOpen?: any,
  afterVotePopup?: any,
  setAfterVotePopup?: any,
  inOutReward?: any,
  setInOutReward?: any,
  rewardExtraVote?: any,
  setRewardExtraVote?: any,
  showReward?: any,
  setShowReward?: any,
  headerExtraVote?: any,
  setHeaderExtraVote?: any,
  showBack?: any;
  setShowBack?: any;
  followerUserId?: string;
  setFollowerUserId?: (lang: string) => void;
  remainingTimer?: any;
  nftAlbumData?: any;
  singalCardData?: any,
  setSingalCardData?: any,
  setNftAlbumData: any;
  allPariButtonTime: any;
  setAllPariButtonTime: any;
  notificationCount: any;
  setNotificationCount: any;
  allButtonTime: any;
  setForRun?: any;
  forRun?: any;
  TradeHistoryShow:any, 
  setTradeHistoryShow:any,
  openOfferTab:any, 
  setOpenOfferTab:any,
  tradeHistoryTab:any, 
  setTradeHistoryTab:any,
  setAllButtonTime: any;
  chosenUserType: string;
  setChosenUserType: (lang: string) => void;
  authStateChanged: boolean;
  timeframes: TimeFrame[];
  setTimeframes: (timeframes: TimeFrame[]) => void;
  lang: string;
  setLang: (lang: string) => void;
  voteRules: VoteRules;
  setVoteRules: (voteRules: VoteRules) => void;
  CPMSettings: CPMSettings;
  setCPMSettings: (CPMSettings: CPMSettings) => void;
  userTypes: UserTypeProps[];
  setUserTypes: (userTypes: UserTypeProps[]) => void;
  translations: Map<string, { [key: string]: string }>;
  setTranslations: (
    translations: Map<string, { [key: string]: string }>
  ) => void;
  languages: string[];
  setLanguages: (languages: string[]) => void;
  rtl: string[];
  setRtl: (rtl: string[]) => void;
  fcmToken: string;
  setFcmToken: (token: string) => void;
  login: boolean;
  setLogin: (l: boolean) => void;
  signup: boolean;
  setSignup: (s: boolean) => void;
  firstTimeLogin: boolean;
  setFirstTimeLogin: (s: boolean) => void;
  menuOpen: boolean;
  setMenuOpen: (o: boolean) => void;
  profileTab: ProfileTabs;
  setProfileTab: (s: ProfileTabs) => void;
  appStats: AppStats;
  setAppStats: (s: AppStats) => void;
  paxData: PaxData;
  setPaxData: (s: PaxData) => void;
  animateBox?:any;
  setAnimateBox?:any;
  setLoginRedirectMessage: (s: string) => void;
  loginRedirectMessage: string;
  isWLDPEventRegistered: boolean,
  setIsWLDPEventRegistered: React.Dispatch<React.SetStateAction<boolean>>,
};

const AppContext = React.createContext({} as AppContextProps);

export const getMenuItems = async () => {
  const url = `${process.env.REACT_APP_SITE_URL}wp-json/wp/v2/menu`;
  const response = await fetch(url);
  const items = await response.json();
  return JSON.stringify(Object.values(items));
};

export const getItems = () => {
  const items = JSON.parse(
    document.getElementById("menu_items")?.innerText || "[]"
  );

  return items
    .map((i: { url: any; guid: any; title: any }) => {
      const { url, guid = "" } = i;
      return url.replace(guid.replace(/\?p=\d+$/, ""), "").replace("/", "");
    })
    .filter((i: any) => i) as string[];
};

export const getTitles = () => {
  const items = JSON.parse(
    document.getElementById("menu_items")?.innerText || "[]"
  );

  return items.map((i: { url: any; guid: any; title: any }) => {
    return i.title;
  });
};

export default AppContext;

AppContext.displayName = "App";
